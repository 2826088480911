import React from 'react';
import {  customURLs, getToken, notAllowedButtons,showContactCredentialCardBorder, NullBadgeUrl, utcToLocalDateTime } from "../../tools/utils";
import { Loader } from '../../components/Loader';
import { useMyCredential } from '../../hooks/useMyCredential';
import { Link, useLocation } from 'react-router-dom';
import useIsMobile from '../../hooks/Helper/useIsMobile';
import { AccessControl, useGlobalContext } from '../../hooks/Helper/GlobalContext';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const MyCredential = ({ headerMarginTop }: { headerMarginTop: number }) => {
    const {ProviderAccessControl}=useGlobalContext();
    const {t}=useTranslation();
    // const { id } = useLocation().state;
    const contactcredentialId = window.location.pathname.split('/')[2];
    const { REACT_APP_BASE_URL,REACT_APP_LMS_BASE_URL } = process.env;
    const myCredentialQuery = useMyCredential(contactcredentialId);
    const myCredentialData = myCredentialQuery.data?.data?.Data;
    const token = getToken()
    const credentialButtons = myCredentialData?.EarnedCredential?.CredentialButtons.find(x => x.CredentialsName === myCredentialData?.EarnedCredential.myCCOLabel)
    ?.MyCredentials || [];
    const handleClick = (event) => {
        const form = document.getElementById('myForm') as HTMLFormElement;
        form.submit();
    }
    const isMobile =useIsMobile();
    const credentialStatusReason = myCredentialData?.EarnedCredential.StatusReason;
    const isRenewal=showContactCredentialCardBorder(myCredentialData ? myCredentialData?.EarnedCredential.ExpiryDate:'',()=>{});

    const state = encodeURIComponent('https://trueb2c.live/wp-admin/');

//     const handleClick=(event)=>{
//      event.preventDefault();
//  //    const form= document.getElementById('myForm') as HTMLFormElement;
//  //    form.submit();
//          const token = getToken();  // Function to get the token
//          const redirect = encodeURIComponent('https://trueb2c.live/testing-personnel');
//          const targetUrl = `https://trueb2c.live/?code=${token}&state=${state}`;
         
//          window.open(targetUrl, '_blank'); 
//      }
    return (

        <div id="main-content" style={{ marginTop: headerMarginTop }}>
            <div className="cco_content_area cco_content_area_0">
                <article className="react-container">

                    <div className="cco_section cco_section_0">
                        <div className="cco_row cco_row_8">
                            <div className="cco_col">
                                <div className="cco_text cco_text_11">
                                    <div className="cco_text_inner">
                                        <strong>
                                            <a href="/testing-personnel">{t("myCredential.breadcrumbsLink")}</a>
                                        </strong>{' '}
                                        {'>'} {myCredentialData?.EarnedCredential?.Name || ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cco_row">
                            <div className="cco_col">
                                {myCredentialQuery.isLoading ? (<Loader />) : myCredentialQuery.error ? (<p>{myCredentialQuery.error.message}</p>) : (
                                    <div className="cco_card cco_card_2" style={{ paddingLeft: "20px" }}>
                                        <div className="cco_card_body">
                                            <div className="cco_card_aside">
                                                <div className="cco_img">
                                                    <span className="cco_img_wrap">
                                                        {/* <img style={{border:myCredentialData?.EarnedCredential.StatusReason==='Approved'?'':"1px solid rgb(0 0 0 / 5%)",borderRadius:myCredentialData?.EarnedCredential.StatusReason==='Approved'?'':"23px"}} alt={myCredentialData?.EarnedCredential?.Name} src={myCredentialData?.EarnedCredential.StatusReason==='Approved'?myCredentialData?.EarnedCredential?.BadgeUrl:NullBadgeUrl} /> */}
                                                        <img style={{ border: (myCredentialData?.EarnedCredential.StatusReason === 'Approved' || myCredentialData?.EarnedCredential.StatusReason==='PendingRenewal') && myCredentialData?.EarnedCredential.BadgeUrl != null ? '' : "1px solid rgb(0 0 0 / 5%)", borderRadius: (myCredentialData?.EarnedCredential.StatusReason === 'Approved' || myCredentialData?.EarnedCredential.StatusReason==='PendingRenewal') && myCredentialData?.EarnedCredential.BadgeUrl != null ? '' : "23px" }} alt={myCredentialData?.EarnedCredential.Name} src={(myCredentialData?.EarnedCredential.StatusReason === 'Approved' || myCredentialData?.EarnedCredential.StatusReason==='PendingRenewal') && myCredentialData?.EarnedCredential.BadgeUrl != null ? myCredentialData?.EarnedCredential.BadgeUrl : NullBadgeUrl} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="cco_card_main" style={{ padding: '10px' }}>
                                    <div className={'cco_text cco_text_9'}>
                                        <div className={'cco_text_inner'}>
                                            <h3><strong>{myCredentialData?.EarnedCredential?.myCCOLabel || ''}</strong></h3>
                                            <div>{t("myCredential.status")}: {myCredentialData?.EarnedCredential.StatusReason ? t(`myCredential.Statuses.${myCredentialData?.EarnedCredential.StatusReason}`,myCredentialData?.EarnedCredential.StatusReason):''}</div>
                                            {myCredentialData?.EarnedCredential?.ExpiryDate && (
                                                <div>
                                                   {t("myCredential.expiryLabel")}:{' '}
                                                    {myCredentialData?.EarnedCredential.ExpiryDate.split('T')[0] ===
                                                    '0001-01-01'
                                                        ? t("myCredential.noExpiry")
                                                        : myCredentialData?.EarnedCredential.ExpiryDate.split('T')[0]}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {credentialStatusReason==='PendingAudit' &&
                              
                              <div className="cco_card_main" style={{padding:'10px' }}>
                              <div className="cco_text cco_text_16" >
                              <div className={'cco_text_inner'}>
                                   {t("myCredential.pendingAuditMessage")} 
                                </div></div></div>
                                }
                                   {credentialStatusReason==='PendingCertification' &&
                                <div className="cco_card_main" style={{padding:'10px' }}>
                                    <div className="cco_text cco_text_16" >
                                    <div className={'cco_text_inner'}> {t("myCredential.pendingCertification")} 
                              </div>  </div></div>
                                }
                                            {isRenewal && credentialStatusReason === 'Approved' &&
                                <div className="cco_row cco_row_1">
                                    <div className="cco_col ">
                                        <ul className="cco_menu cco_menu_3">
                                            <li
                                                className="cco_menu_item cco_menu_item_primary"
                                                style={{
                                                    marginBottom: '0px',
                                                }}
                                            >
                                               
                                                    <Link to ={`/credential-renewal/${contactcredentialId}`} state={{ eot:contactcredentialId}}  style={{ height: 'auto' }} >
                                                 
                        
                                                
                                                    {t("myCredential.renewButton")} 
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
}
                            </div>
                        </div>
                    )}
                </div>
            </div>

                        {/* WorkShop Lists */}
                        {myCredentialData?.EarnedCredential.WorkShops == true && (
                            <><div className="cco_row cco_row_2">
                                <div className="cco_col">
                                    <div className="cco_text cco_text_2">
                                        <div className="cco_text_inner">
                                            <h2>  {t("myCredential.workshopTitle")} </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <div className="cco_row cco_row_3">
                                    <div className="cco_col">
                                        {myCredentialQuery.isLoading ? (<Loader />) : myCredentialQuery.error ? (<p>{myCredentialQuery.error.message}</p>) : (
                                            myCredentialData?.EarnedCredential.workshopApplications.length > 0 ? (
                                                <ul className="cco_accordion">
                                                    {myCredentialData?.EarnedCredential.workshopApplications.map((WorkShop, index: number) => {
                                                        return (
                                                            <li className="cco_accordion_item active" key={index}>
                                                                <div className="cco_accordion_item_body">
                                                                    <table className="cco_table">
                                                                        <tbody className="cco_table_body">
                                                                            <tr className="cco_table_row">
                                                                                <td className="cco_table_cell cco_table_cell_large">
                                                                                    <div className="cco_blurb cco_blurb_0">
                                                                                        <div className="cco_blurb_label">{t("myCredential.startEnd")}</div>
                                                                                        <div className="cco_blurb_value">
                                                                                            {/* <strong title='EST DateTime'>{moment(WorkShop.StartDateTime).tz('America/New_York').format("MM-DD-YYYY hh:mmA") || '-'}</strong> */}
                                                                                            <strong>   {(WorkShop.Format === 'Virtual')?utcToLocalDateTime(WorkShop.StartDateTime  || '-'): (moment(WorkShop.StartDateTime).format('MM-DD-YYYY hh:mmA') + " (" + (WorkShop.StartdateTimeZone || '-') + ")")}</strong>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="cco_blurb cco_blurb_0">
                                                                                        <div className="cco_blurb_label">{t("myCredential.name")}</div>
                                                                                        <div className="cco_blurb_value">
                                                                                            <strong>{WorkShop.WorkShopName || '-'}</strong>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="cco_blurb cco_blurb_align_right cco_blurb_0">
                                                                                        <div className="cco_blurb_label">{t("myCredential.launchLink")}</div>
                                                                                        <div className="cco_blurb_value">
                                                                                            {/* <strong>{WorkShop.LaunchLink || '-'}</strong> */}
                                                                                            <a target={WorkShop.LaunchLink ? '_blank' : ''} href={WorkShop.LaunchLink ? WorkShop.LaunchLink.toUpperCase().startsWith('W') ? 'https://' + WorkShop.LaunchLink : WorkShop.LaunchLink : 'javascript:void(0)'}>{WorkShop.LaunchLink || '-'}</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="cco_table_cell">
                                                                                    <div className="cco_blurb cco_blurb_align_right cco_blurb_0">
                                                                                        <div className="cco_blurb_label">{t("myCredential.status")}</div>
                                                                                        <div className="cco_blurb_value">
                                                                                            <strong>{t(`myCredential.workshopStatuses.${WorkShop.Status}`,WorkShop.Status) || '-'}</strong>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="cco_blurb cco_blurb_0">
                                                                                        <div className="cco_blurb_label">{t("myCredential.location")}</div>
                                                                                        <div className="cco_blurb_value">
                                                                                            <strong>{WorkShop.LocationName || '-'}</strong>
                                                                                        </div>
                                                                                    </div>


                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </li>
                                                        )


                                                    })}
                                                </ul>
                                            ) : (
                                                <p>{t("myCredential.noWorkshop")}</p>
                                            )
                                        )}
                                    </div>
                                </div></>
                        )}

                        <div className="cco_row cco_row_2">
                            <div className="cco_col">
                                <div className="cco_text cco_text_2">
                                    <div className="cco_text_inner">
                                        <h2><strong>{myCredentialData?.EarnedCredential?.Name || ''} {t("myCredential.description")}</strong></h2>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="cco_row">
                            <div className="cco_col">
                                {myCredentialQuery.isLoading ? (<Loader />) : myCredentialQuery.error ? (<p>{myCredentialQuery.error.message}</p>) : (
                                    <div className="cco_card cco_card_2">

                                        <div className="cco_card_body">
                                        </div>
                                        {/* Credential Description */}
                                        
                                        {myCredentialData?.EarnedCredential.ShortDescription && (
                                            <div className="cco_card_footer">
                                                <div className="cco_text">
                                                    <div className="cco_text_inner" dangerouslySetInnerHTML={{ __html: myCredentialData?.EarnedCredential?.ProductDetails || '' }}></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>

                        </div>


                        {ProviderAccessControl ===AccessControl.Locked || ProviderAccessControl=== AccessControl.Suspended?(null):(
                
                <div className="cco_row cco_row_1" style={{marginBottom:'12px'}}>
                    <div className="cco_col">
                        <ul className="cco_menu cco_menu_0">
                            {credentialButtons?.map((button: any, index: number) => {
                                // if (index > 0 && button?.button_text) {
                                    if (
                                        (myCredentialData?.EarnedCredential.StatusReason === 'Pending' || 'PendingRenewal') &&
                                        button?.button_text === 'Open Online Learning Center'
                                    ) {
                                        return (
                                            <li className="cco_menu_item cco_menu_item_primary" key={index}>
                                                {/* <a style={{ maxWidth: "400px" }} href={button?.button_url || '#'}>{button?.button_text}</a> */}
                                                <form 
                                                 action={`${REACT_APP_LMS_BASE_URL}/dashboard`} 
                                              id='myForm' method="post">
                                                            <input type="hidden" name="state" 
                                                             value={`${REACT_APP_LMS_BASE_URL}/wp-admin/`}
                                                             />
                                                            <input type="hidden" name="id_token" value={token} />
                                                            <a style={{ maxWidth: "400px" }} 
                                                             onClick={handleClick} 
                                                            href='#' >{button?.button_text}</a>

                                                        </form>
                                            
                                            </li>
                                        );
                                    }
                                    if (myCredentialData?.EarnedCredential.StatusReason === 'Approved' || myCredentialData?.EarnedCredential.StatusReason==='PendingRenewal') {
                                        return (
                                            <li className="cco_menu_item cco_menu_item_primary" key={index}>
                                                <a style={{ maxWidth:credentialButtons.length===1?"400px":''}}
                                                 href={button?.button_url || '#'}
                                                 >{button?.button_text}</a>
                                            </li>
                                            
                                        );
                                    }
                                    else {
                                        return null;
                                    }
                            })}

                            {(myCredentialData?.EarnedCredential.StatusReason === 'Approved' || myCredentialData?.EarnedCredential.StatusReason==='PendingRenewal') &&  !notAllowedButtons.includes(myCredentialData.EarnedCredential.Name) && (
                                <><li className="cco_menu_item cco_menu_item_primary">
                                    <a
                                        href={`${token && customURLs?.manageMyTestAdministrationsUrl?.button_url
                                            ? customURLs.manageMyTestAdministrationsUrl.button_url + '?id_token=' + token
                                            : '#'}`}
                                        target="_blank"
                                        rel="noreferrer">
                                        {t("myCredential.manageMyTestAdministrations") || "button_text"}
                                        {/* t("myCredential.manageMyTestAdministrations") */}
                                    </a>
                                </li>
                                {/* {myCredentialData.EarnedCredential.ResourcesURL &&  myCredentialData.EarnedCredential.Name !== "Proctor-Practical" &&
                                <li className="cco_menu_item cco_menu_item_primary" >
                                        <a
                                        //  href={myCredentialData.EarnedCredential.ResourcesURL}
                                        >{myCredentialData.EarnedCredential.myCCOLabel} Resources</a>
                                </li>
                                } */}
                                     {myCredentialData.EarnedCredential.CredentialLocalizationDetails &&
                                <li className="cco_menu_item cco_menu_item_primary" >
                                      <Link to={`/resource/${contactcredentialId}`}> {myCredentialData.EarnedCredential.myCCOLabel} {t("myCredential.Resources")}</Link>

                                </li>
                                }
                                 {/* {(myCredentialData?.EarnedCredential.StatusReason === 'Approved'|| myCredentialData?.EarnedCredential.StatusReason==='PendingRenewal') &&  myCredentialData.EarnedCredential.Name === "Proctor-Practical" &&
                                <li className="cco_menu_item cco_menu_item_primary" >
                                    <Link to='/generatepdf'>{myCredentialData.EarnedCredential.myCCOLabel} Generate PDF</Link>
                                </li>
                                } */}

                                
                                    </>
                            )}
                            
                        </ul>
                    </div>
                </div>
            )}
            {(myCredentialData?.EarnedCredential.StatusReason === 'Approved' || myCredentialData?.EarnedCredential.StatusReason==='PendingRenewal')&& myCredentialData.EarnedCredential.Name === 'TSC-EOT'  &&(
            <div className="cco_row cco_row_1">
            <div className="cco_col">
                    <ul className="cco_menu cco_menu_0">
                <li className="cco_menu_item cco_menu_item_primary">
                                     <a style={{maxWidth:!isMobile?'300px':''}}
                                        href={`${token &&
                                            customURLs?.paperPencilTestingUrl?.button_url
                                                ? customURLs.paperPencilTestingUrl.button_url + '?id_token=' + token
                                                : '#'
                                        }`}
                                        target="_blank">
                                        {t("myCredential.paperPencilTesting") || 'Button Text'}
                                    </a>
                                    <div style={{textAlign:"center"}}>
                                    <span>
                                 
                                   </span>
                                    </div>
                                </li>
                                </ul></div></div>
              
            )}
                    </div>
                </article>
            </div>
        </div>
    );
};
