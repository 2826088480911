import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Header } from './components/Common/Header';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Login } from './containers/Login/Login';
import { Dashboard } from './containers/Dashboard/Dashboard';
import { EventType, InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import './sass/main.scss';
import { NotFound404 } from './components/Authentication/NotFound404';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { getUserData, setContactId, setTokenId, setUserData } from './tools/utils';
import { TestingPersonnel } from './containers/TestingPersonnel';
import { Credential } from './containers/Credentials/Credential';
import { MyCredential } from './containers/Credentials/MyCredential';
import { Account } from './containers/Account';
import { NewLocation } from './containers/NewLocation';
import { TSCEOT } from './containers/TSCEOT';
import ProtectedRoute from './components/Authentication/PrivateRoute';
import { tokenValidation } from './hooks/MSAL/TokenService';
import { TSCPRAC } from './containers/TSCPRAC';
import { NewPracLocation } from './containers/NewPracLocation';
import { CustomCAD } from './containers/NewCustomCAD';
import { CadManagement } from './containers/CADManagement';
import { routeTitle } from './routeMap';
import { msalConfig } from './authConfig';
import { Footer } from './components/Common/Footer';
import { PhotoManagement } from './containers/PhotoManagement';
import { CredentialJourney } from './containers/Credentials/CredentialJourney';
import { AvailableCredentials } from './containers/Credentials/AvailableCredentials';
import { I18nextProvider } from 'react-i18next';
import { LocationRenewal } from './containers/LocationRenewal/LocationRenewal';
import i18n from '../src/Localization/I18next';
import { GlobalContextProvider} from './hooks/Helper/GlobalContext';
import { GeneratePDF } from './containers/GeneratePDF/GeneratePDF';
import { CredentialRenewal } from './containers/Credentials/CredentialRenewal';
import { Resouces } from './containers/Resources/Resources';
import PrivateRoute from './components/Authentication/PrivateRoute';
import { CardOrderForm } from './containers/CardOrderForm/CardOrderForm';



const queryClient = new QueryClient();



const Pages = () => {
  const [headerMarginTop, setHeaderMarginTop] = useState(0);
    const { instance, accounts } = useMsal();
    useEffect(() => {
        const activeAccount = accounts[0];
        if (activeAccount) {
          const userData = getUserData();
          if (userData) {
            const currentTime = new Date();
            const exp = new Date(userData.tokenExpiry);
            const isReadytoGetToken=(currentTime > exp || exp.getTime() - currentTime.getTime() <= 10 * 60 * 1000);
            if (currentTime > exp) {
              const logoutRequest = {
                  account: activeAccount,
                  postLogoutRedirectUri: "/", // Redirect to home after logout
              };

              instance.logoutRedirect(logoutRequest)
                  .catch((error) => console.log(error));
              
              localStorage.clear();
          } else {
              // Set timeout to auto-logout when token expires
              const remainingTime = exp.getTime() - currentTime.getTime();
              setTimeout(() => {
                  const logoutRequest = {
                      account: activeAccount,
                      postLogoutRedirectUri: "/",
                  };
                  
                  instance.logoutRedirect(logoutRequest)
                      .catch((error) => console.log(error));
                  
                  localStorage.clear();
              }, remainingTime);
          }
      


            if (userData.accessToken && isReadytoGetToken ) {
              const publicClientApplication = new PublicClientApplication(msalConfig);
              const request = {
                scopes: ['openid', msalConfig.auth.clientId], 
              };
              publicClientApplication.acquireTokenSilent(request).then(response => {
                if (response.accessToken) {
                  setUserData(response);
                  console.log('AcquireToken successful');
                  console.log(response);
                  
                }
              }).catch(error => {
                if (error instanceof InteractionRequiredAuthError) {
                  // Refresh token has expired or user interaction is required
                  instance.acquireTokenRedirect(request); // Redirect the user to log in again
              } else {
                  console.error("Token acquisition failed", error);
              }
              });
            } else {
                const remainingTimeSeconds = Math.ceil((exp.getTime() - currentTime.getTime()) / 1000); // Remaining time in seconds
                const hours = Math.floor(remainingTimeSeconds / 3600);
                const minutes = Math.floor((remainingTimeSeconds % 3600) / 60);
                const seconds = remainingTimeSeconds % 60;
                const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                console.log(`Token will expire in ${formattedTime}.`);
              setTokenId(userData.accessToken);
              
            }

            if (!userData.contactId) {
                setContactId(activeAccount.idTokenClaims.extension_ContactId);
              } else {
                setContactId(userData.contactId);
              }
        
          }
        }



    
        const callbackId = instance.addEventCallback((event) => {
          if (
              (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
              event.payload.account
          ) {
              setUserData(event.payload); // Update user data on successful login or token acquisition
          }
          if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE || event.eventType === EventType.SSO_SILENT_FAILURE) {
            console.error("Token acquisition failed. Logging out...");
            instance.logout();
          }
    
          if (event.eventType === EventType.LOGOUT_SUCCESS) {
            console.log("User successfully logged out.");
            // You could redirect the user or handle UI updates here
          }
      });
  
      return () => {
          if (callbackId) {
              instance.removeEventCallback(callbackId);
          }
      };
      // eslint-disable-next-line
  }, [instance, accounts]);
   
    return (
      <>
       {/* Render Header if not in maintenance mode and not on the maintenance page */}
      <Header setHeaderMargin={setHeaderMarginTop} />
      
      <Routes>
      
            <Route path="/" element={accounts.length > 0 ? <Navigate to="/dashboard" /> : <Login headerMarginTop={headerMarginTop} />} />
            <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} headerMarginTop={headerMarginTop}/>}/>
            <Route path="/testing-personnel" element={<ProtectedRoute component={TestingPersonnel}  headerMarginTop={headerMarginTop} />} />
            <Route path="/credential/:id?" element={<ProtectedRoute component={Credential} headerMarginTop={headerMarginTop}  />} />
            <Route path="/my-credential/:id?" element={<ProtectedRoute component={MyCredential} headerMarginTop={headerMarginTop} />} />
            <Route path="/account" element={<ProtectedRoute component={Account} headerMarginTop={headerMarginTop}  />} />
            <Route path="/photo-management" element={<ProtectedRoute component={PhotoManagement} headerMarginTop={headerMarginTop} />} />
            <Route path="/credential-journey" element={<ProtectedRoute component={CredentialJourney} headerMarginTop={headerMarginTop} />} />
            <Route path="/available-credentials" element={<ProtectedRoute component={AvailableCredentials} headerMarginTop={headerMarginTop} />} />
            <Route path="/tsc-prac/new-prac" element={<ProtectedRoute component={NewPracLocation} headerMarginTop={headerMarginTop} />} />
            <Route path="/tsc-prac/prac-edit" element={<ProtectedRoute component={NewPracLocation} headerMarginTop={headerMarginTop}  />} />
            <Route path="/tsc-eot/location-new" element={<ProtectedRoute component={NewLocation} headerMarginTop={headerMarginTop} />} />
            <Route path="/tsc-eot/location-edit/:id?" element={<ProtectedRoute component={NewLocation} headerMarginTop={headerMarginTop}  />} />
            <Route path="/tsc-eot" element={<ProtectedRoute component={TSCEOT} headerMarginTop={headerMarginTop} />} />
            <Route path="/tsc-prac" element={<ProtectedRoute component={TSCPRAC} headerMarginTop={headerMarginTop} />} />
            <Route path="/tsc-eot/location-renewal" element={<ProtectedRoute component={LocationRenewal} headerMarginTop={headerMarginTop}  />} />
            <Route path="/tsc-prac/location-renewal" element={<ProtectedRoute component={LocationRenewal} headerMarginTop={headerMarginTop}  />} />
            <Route path="/tsc-prac/custom-cad" element={<ProtectedRoute component={CadManagement} headerMarginTop={headerMarginTop}  />} />
            <Route path="/tsc-prac/custom-cad/new-cad" element={<ProtectedRoute component={CustomCAD} headerMarginTop={headerMarginTop}/>} />
            <Route path="/resource/:id?" element={<ProtectedRoute component={Resouces} headerMarginTop={headerMarginTop} />} />
            <Route path="/generatepdf" element={<ProtectedRoute component={GeneratePDF} headerMarginTop={headerMarginTop}/>} />
            <Route path="/credential-renewal/:id?" element={<ProtectedRoute component={CredentialRenewal} headerMarginTop={headerMarginTop}/>} />
            <Route path="/wallet-order-card" element={<ProtectedRoute component={CardOrderForm} headerMarginTop={headerMarginTop}/>} />
            <Route path="*" element={<NotFound404 />} />
     
      </Routes>

      {/* Render Footer if not in maintenance mode and not on the maintenance page */}
      { accounts.length > 0 && <Footer />}
    </>
  );
};


const App = () => {
    const { pathname } = useLocation();
    document.title = routeTitle[pathname]?routeTitle[pathname]: ''  + ' | NCCCO';
    return (
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <GlobalContextProvider >
            <Pages />
            </GlobalContextProvider>
        </QueryClientProvider>
        </I18nextProvider>
    );
}

export default App;

