import { useCredential } from "../../hooks/useCredential";
import React, { useEffect, useState } from "react";
import { BlockUI, Loader } from "../../components/Loader";
import { HostedForm } from "react-acceptjs";
import { ErrorMessage, Field, Formik, useFormikContext } from "formik";
import { createcardOrderFormSchema, createcredentialSchema } from "../../schemas/credential";
import InputMask from "react-input-mask";
import { useAuthData } from "../../hooks/useAuthData";
import { getContactId } from "../../tools/utils";
import { Link } from "react-router-dom";

import useIsMobile from "../../hooks/Helper/useIsMobile";
import AuthorizeSessionTimer from "../../components/PaymentTimer/AuthorizeSessionTimer";
import { useTranslation } from "react-i18next";
import { CreateCardOrder, IValueCardOrder } from "../../types/cardorder";
import { useCreateCardOrder } from "../../hooks/useCreateCardOrder";
import { useUser } from "../../hooks/useUser";
import { useGlobalContext } from "../../hooks/Helper/GlobalContext";
import { ProfileCard } from "./ProfileCard";
import { Root } from "../../types/dashboard";
import { useCountries } from "../../hooks/useCountries";
import { useStates } from "../../hooks/useStates";
import { useLocationProduct } from "../../hooks/useLocationProduct";
import { LocationProductData } from "../../types/product";
import { useValidateCardOrder } from "../../hooks/useValidateCardOrder";
import { TransactionSuccessModal } from "../../components/Common/TransactionSuccessModal";
export const CardOrderForm = ({
  headerMarginTop,
}: {
  headerMarginTop: number;
}) => {
  /* eslint-disable no-restricted-globals */
  /* eslint-disable no-unused-expressions */
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const id = window.location.pathname.split("/")[2];
  const contactId = getContactId();
  const authQuery = useAuthData("contact-credential");
  const productQuery = useLocationProduct('Replacement Certification Card');
  const productData = productQuery.data?.data.Data || {} as LocationProductData;
  const [isModalOpen, setIsModalOpen] = useState(false);

  
  
  const {
    CandidateAccessControl,
    CandidateBannerMessage,
    PhotoBannerMessage,
    CandidateBannerColor,
    Color,
    ProviderAccessControl,
    CandidateId,
  } = useGlobalContext();
  const query = useUser();
      const countriesQuery = useCountries();
      const validateQuery = useValidateCardOrder();
      const validateData = validateQuery.data?.data.Data;
      console.log('validateData', validateData)
      const countriesData = countriesQuery.data?.data.Data || [];

  const data = (query.data?.data || {}) as unknown as Root['data'];

  const AuthData = authQuery.data?.data.Data;

  const [termsAndConditions, setTermsAndConditions] = useState(true);
  const [useContactAddress, setUseContactAddress] = useState(false);
  const [checkout, setcheckout] = useState(false);
  const [isCreditInfo, setCreditInfo] = useState(false);
  const [steps, setSteps] = useState(0);

  const createCardOrder = useCreateCardOrder();

  const [creditData, setCreditData] = useState({
    LastFour: "",
    dataDescriptor: "",
    dataValue: "",
    CardNumber: "",
  });
  const isMobile = useIsMobile();
  let CreateCardObj: CreateCardOrder = {
    ContactId: contactId,
    ProductId: productData.ProductId,
    Payment: {
      Amount: 0,
      AuthNetTransactionID: "",
      CreditCardType: "",
      LastFour: "",
      Paymenttype: "Credit Card",
      PaymentData: {
        dataDescriptor: "",
        dataValue: "",
        Address: "",
        City: "",
        FirstName: "",
        LastName: "",
        State: "",
        Zip: "",
        BillToEmail: "",
        BillToName: "",
        BillingPhone: "",
        BillingCountry: "",
      },
    },
    ShippingInformation: {
      Attention: "",
      StreetAddress1: "",
      StreetAddress2: "",
      City: "",
      State: "",
      ZipCode: "",
      Country: "",
    }
  };
  const initialValues:IValueCardOrder = {
    Address: "",
    BillToEmail: "",
    City: "",
    FirstName: "",
    LastName: "",
    Zip: "",
    State: "",
    BillingPhone: "",
    BillingCountry: "",
      Attention: "",
      StreetAddress1: "",
      StreetAddress2: "",
      ShippingCity: "",
      ShippingState: "",
      ZipCode: "",
      Country: "",

  };

  const handleSubmits = (response: any) => {
    if (response.messages.message[0].code === "I_WC_01") {
      setCreditInfo(true);
      setCreditData({
        LastFour: response.encryptedCardData.cardNumber.replaceAll("X", ""),
        dataDescriptor: response.opaqueData.dataDescriptor,
        dataValue: response.opaqueData.dataValue,
        CardNumber: response.encryptedCardData.cardNumber,
      });
    }
  };

  const handleTermsAndConditionsChange = (event: any) => {
    setTermsAndConditions(event.target.checked);
  };

  const handleUseContactAddress = (event: any) => {
    setUseContactAddress(event.target.checked);
  };
  const processedtocheckout = (value: boolean) => {
    setcheckout(value);
  };

  const isValidated = !termsAndConditions;

  // const reset = () => {
  //   credentials.refetch();
  // };

  useEffect(() => {
    // reset();
  }, [language]);


  const States = () => {
      const formik = useFormikContext<any>();
      const { t } = useTranslation();
      const statesQuery = useStates('default' === formik.values.Country ? undefined : formik.values.Country);
      const statesLoading = 'idle' !== statesQuery.fetchStatus && statesQuery.isLoading;
      const statesData = statesQuery.data?.data.Data || [];
  
      return (
          <li
              className="cco_form_fieldset_item cco_form_fieldset_item_small"
              data-tooltip={'default' === formik.values.ShippingState ? t("account.personalForm.selectCountry") : undefined}>
              <label htmlFor="ccoState">
              {t("account.personalForm.state")}<span style={{ color: '#db3a3a' }}>*</span>
              </label>
  
              {statesLoading && <Loader scale={0.6} fieldset={true} />}
  
              <Field as="select" id="ccoState" name="ShippingState" disabled={'default' === formik.values.Country || statesLoading}>
                  <option value="default" disabled={true}>
                      {statesLoading ? 'Loading...' : t("account.personalForm.selectState")}
                  </option>
  
                  {statesData.map(state => (
                      <option key={state.ProvinceId} value={state.ProvinceId}>
                          {state.ProvinceName}
                      </option>
                  ))}
              </Field>
  
              <ErrorMessage component={'div'} name={'ShippingState'} className="cco_form_message cco_form_message_error" />
  
              {statesQuery.error && <div className="cco_form_message cco_form_message_error">{statesQuery.error.message}</div>}
  
              {statesQuery.data && statesData.length < 1 && (
                  <div className="cco_form_message cco_form_message_error">{t("account.personalForm.noState")}</div>
              )}
          </li>
      );
  };
  

  return (
    <>
      <div id="main-content" style={{ marginTop: headerMarginTop }}>
        <div className="cco_content_area cco_content_area_0">
          <article className="react-container">
            <div className="cco_section cco_section_0">
              <div className="cco_row cco_row_8">
                <div className="cco_col">
                  <div className="cco_text cco_text_11">
                    <div className="cco_text_inner">
                      <strong>
                        {/* <a href="/testing-personnel">Testing Personnel</a> */}
                        <Link
                          to={"/dashboard"}
                          onClick={() => {
                            // reset();
                          }}
                        >
                          {t("CardOrder.headerDashboard")}
                        </Link>
                      </strong>{" "}
                      {">"} {t("CardOrder.headerCardOrder")}
                      {isCreditInfo && (
                        <AuthorizeSessionTimer startTimer={isCreditInfo} />
                      )}
                    </div>
                  </div>
                </div>
              </div>


               <div className="cco_row" style={{ marginBottom: '20px' }}>
                                <div className="cco_col">
                                    {validateQuery.isLoading ? (
                                        <Loader />
                                    ) : validateQuery.error ? (
                                        <p>{validateQuery.error.message}</p>
                                    ) : !validateData ? (
                                        <div className="cco_text cco_text_12">
                                            <div className="cco_text_inner">
                                                <p>
                                                {t("CardOrder.notEligible")}  {' '}
                                                </p>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div> 
{validateData && (
      <><ProfileCard
                  CandidateAccessControl={CandidateAccessControl}
                  query={query}
                  data={data} /><>
                    <Formik
                      enableReinitialize={true}
                      initialValues={initialValues}
                      validationSchema={createcardOrderFormSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        if (isCreditInfo == true) {
                          CreateCardObj = {
                            ContactId: contactId,
                            ProductId: productData.ProductId,
                            ShippingInformation: {
                              Attention: values.Attention,
                              City: values.ShippingCity,
                              Country: values.Country,
                              State: values.ShippingState,
                              StreetAddress1: values.StreetAddress1,
                              StreetAddress2: values.StreetAddress2,
                              ZipCode: values.ZipCode
                            },
                            Payment: {
                              Amount: 0,
                              AuthNetTransactionID: "",
                              CreditCardType: "",
                              LastFour: creditData.LastFour,
                              Paymenttype: "Credit Card",
                              PaymentData: {
                                dataDescriptor: creditData.dataDescriptor,
                                dataValue: creditData.dataValue,
                                Address: values.Address,
                                City: values.City,
                                FirstName: values.FirstName,
                                LastName: values.LastName,
                                State: values.State,
                                Zip: values.Zip,
                                BillToEmail: values.BillToEmail,
                                BillToName: values.FirstName + " " + values.LastName,
                                BillingCountry: values.BillingCountry,
                                BillingPhone: values.BillingPhone,
                              },
                            },
                          };
                          
                          createCardOrder
                            .mutateAsync(CreateCardObj)
                            .then((res) => {
                              if (res?.data?.ApiCode === 200 &&
                                res.success === true) {
                                setSubmitting(false);
                                setIsModalOpen(true);
                                // window.location.href = "/dashboard";
                              }
                            })
                            .finally(() => {
                              setSubmitting(false);
                            })
                            .catch((err) => {
                              console.log(err);
                              setSubmitting(false);
                            });
                        } else {
                          setSubmitting(false);
                          return;
                        }
                      } }
                    >
                      {({
                        values, isSubmitting, handleSubmit, handleChange, handleBlur, setFieldValue, handleReset, isValid, dirty,
                      }) => (
                        <form
                          onReset={handleReset}
                          onSubmit={(e) => {
                            if (isSubmitting) {
                              e.preventDefault();
                            } else {
                              handleSubmit(e);
                            }
                          } }
                          className="cco_form"
                        >
                          <div className="cco_row">
                            <div className="cco_col">
                              <>
                                <div className="cco_form_row">
                                  <div className="cco_form_col">
                                    <h2 className="cco_form_heading">
                                      {t("CardOrder.subheading")} 
                                    </h2>
                                    <p style={{ paddingBottom: "20px" }}>
                                      <strong>
                                      {t("CardOrder.walletCostNote")} 
                                        
                                      </strong>
                                    </p>
                                  </div>
                                </div>
                              </>
                            </div>
                            {steps == 0 && (
                              <div className="cco_text cco_text_0">
                                <div className="cco_text_inner">
                                  <h2 className="cco_form_heading">
                                  {t("CardOrder.shippingInformation.title")} 
                                  </h2>
                                  <ul className="cco_menu cco_menu_1">
                                    <li className="small_checkbox_item">
                                      <label className="small_checkbox_wrapper">
                                        <input
                                          name="termsAndConditions"
                                          id="smallTermsAndConditions"
                                          type="checkbox"
                                          className="small_checkbox_input"
                                          value="termsAndConditions"
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              setUseContactAddress(e.target.checked);
                                              setFieldValue("StreetAddress1", data.Addresses[0].Street1);
                                              setFieldValue("StreetAddress2", data.Addresses[0].Street2);
                                              setFieldValue("Country", data.Addresses[0].CountryId);
                                              setFieldValue("ShippingCity", data.Addresses[0].City);
                                              setFieldValue("ShippingState", data.Addresses[0].StateId);
                                              setFieldValue("ZipCode", data.Addresses[0].Zip);
                                              setFieldValue("Attention", data.Name.FullName);
                                            }

                                            else {
                                              setFieldValue("StreetAddress1", "");
                                              setFieldValue("StreetAddress2", "");
                                              setFieldValue("Country", data.Addresses[0].CountryId);
                                              setFieldValue("ShippingCity", "");
                                              setFieldValue("ShippingState", data.Addresses[0].StateId);
                                              setFieldValue("ZipCode", "");
                                              setFieldValue("Attention", "");
                                            }



                                          } } />
                                        <span
                                          className="small_checkbox_mark"
                                          style={{
                                            border: termsAndConditions
                                              ? "2px solid #c67b1d"
                                              : "1px solid #c67b1d",
                                            cursor: "pointer",
                                          }}
                                        ></span>
                                        <span className="small_checkbox_text">
                                  {t("CardOrder.shippingInformation.shippingCheck")} 
                                        </span>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )}

                          </div>

                          {steps === 0 && (
                            <><div className="cco_row cco_row_2">
                              <div className="cco_col">
                                <ul className="cco_form_fieldset">
                                  <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                    <label htmlFor="ccoStreet1">
                                    {t("CardOrder.shippingInformation.Attention")} 
                                    {" "}
                                      <span style={{ color: "#db3a3a" }}>*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      id="ccoStreet1"
                                      name="Attention" />
                                    <ErrorMessage
                                      name={"Attention"}
                                      component={"div"}
                                      className="cco_form_message cco_form_message_error" />
                                  </li>

                                  <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                    <label htmlFor="ccoStreet1">
                                    {t("CardOrder.shippingInformation.Street1")}{" "}
                                      <span style={{ color: "#db3a3a" }}>*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      id="ccoStreet1"
                                      name="StreetAddress1" />
                                    <ErrorMessage
                                      name={"StreetAddress1"}
                                      component={"div"}
                                      className="cco_form_message cco_form_message_error" />
                                  </li>

                                  <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                    <label htmlFor="ccoStreet2">
                                    {t("CardOrder.shippingInformation.Street2")}{" "}
                                     
                                    </label>
                                    <Field
                                      type="text"
                                      id="ccoStreet2"
                                      name="StreetAddress2" />
                                    <ErrorMessage
                                      name={"StreetAddress2"}
                                      component={"div"}
                                      className="cco_form_message cco_form_message_error" />
                                  </li>

                                  <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                    <label htmlFor="ccoBillingCountry">
                                      {t("account.personalForm.country")}  <span style={{ color: '#db3a3a' }}>*</span>
                                    </label>

                                    {countriesQuery.isLoading && <Loader scale={0.6} fieldset={true} />}

                                    <Field as="select" id="ccoBillingCountry" name="Country" disabled={countriesQuery.isLoading}>
                                      <option value="default" disabled={true}>
                                        {countriesQuery.isLoading ? 'Loading...' : t("account.personalForm.selectaCountry")}
                                      </option>

                                      {countriesData.map(country => {
                                        return (
                                          <option key={country.CountryId} value={country.CountryId}>
                                            {country.CountryName}
                                          </option>
                                        );
                                      })}
                                    </Field>

                                    <ErrorMessage
                                      component={'div'}
                                      name={'Country'}
                                      className="cco_form_message cco_form_message_error" />

                                    {countriesQuery.error && (
                                      <div className="cco_form_message cco_form_message_error">{countriesQuery.error.message}</div>
                                    )}

                                    {countriesQuery.data && countriesData.length < 1 && (
                                      <div className="cco_form_message cco_form_message_error">
                                        {t("account.personalForm.noCountry")}

                                      </div>
                                    )}
                                  </li>

                                  <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                    <label htmlFor="ccoCity">
                                    {t("CardOrder.shippingInformation.city")}{" "}
                                      <span style={{ color: "#db3a3a" }}>*</span>
                                    </label>
                                    <Field type="text" id="ccoCity" name="ShippingCity" />
                                    <ErrorMessage
                                      name={"ShippingCity"}
                                      component={"div"}
                                      className="cco_form_message cco_form_message_error" />
                                  </li>

                                  <States />
                                  <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                    <label htmlFor="ccoFullName">
                                    {t("CardOrder.shippingInformation.zip")}{" "}
                                      <span style={{ color: "#db3a3a" }}>*</span>
                                    </label>
                                    <Field type="text" id="ccoCity" name="ZipCode" />
                                    <ErrorMessage
                                      name={"ZipCode"}
                                      component={"div"}
                                      className="cco_form_message cco_form_message_error" />
                                  </li>
                                </ul>
                              </div>
                              <div style={{ fontSize: 14, fontStyle: 'italic',marginTop: 10 }}>
                                <small>
                                {t("CardOrder.shippingInformation.note")} <a href="https://my.ccocert.org/account">{t("CardOrder.shippingInformation.noteLink")}</a>.
                                </small>
                            </div>
                        

                            </div>
                              <ul className="cco_menu cco_menu_7" style={{ float: "right" }}>
                                <li className="cco_menu_item">
                                  <button
                                    className="cco_form_submit"
                                    type={"button"}
                                    onClick={() => {
                                      if (values.StreetAddress1 &&
                                        values.Country &&
                                        values.ShippingCity &&
                                        values.ZipCode &&
                                        values.Attention) {
                                        setSteps(1);
                                      }


                                    } }
                                    style={{
                                      opacity: (
                                        values.StreetAddress1 &&
                                        values.Country &&
                                        values.ShippingCity &&
                                        values.ZipCode &&
                                        values.Attention
                                      )
                                        ? "1"
                                        : ".5",
                                      cursor: (
                                        values.StreetAddress1 &&
                                        values.Country &&
                                        values.ShippingCity &&
                                        values.ZipCode &&
                                        values.Attention
                                      )
                                        ? "pointer"
                                        : "not-allowed",
                                    }}
                                  >
                                    {t("CardOrder.shippingInformation.nextButton")}
                                  </button>
                                </li>
                              </ul></>
                          )}

                          {steps === 1 && (
                            <div className="cco_row">
                              <div className="cco_col">
                                <>
                                  <div className="cco_form_row">
                                    <div className="cco_form_col">
                                      <h2 className="cco_form_heading">
                                        {t("CardOrder.billingInformation.title")}
                                      </h2>
                                      <p style={{ paddingBottom: "20px" }}>
                                        <strong>
                                        {t("CardOrder.billingInformation.note")}
                                        </strong>
                                      </p>
                                      <div className="cco_form_wrap">
                                        <div className="cco_form_row">
                                          <div
                                            className="cco_form_col"
                                            style={{ marginBottom: "10px" }}
                                          >
                                            <div className="cco_form_wrap">
                                              <div
                                                className="cco_blurb cco_blurb_4"
                                                style={{ alignItems: "center" }}
                                              >
                                                {isCreditInfo && (
                                                  <>
                                                    <div className="cco_blurb_main">
                                                      {" "}
                                        {t("CardOrder.billingInformation.cardNumber")}
                                                    </div>
                                                    <div className="cco_blurb_aside">
                                                      {creditData?.CardNumber || ""}
                                                    </div>
                                                  </>
                                                )}
                                                {!isCreditInfo && (
                                                  <>
                                                    <HostedForm
                                                      buttonText={t("CardOrder.billingInformation.hostedFormText")}
                                                      formButtonText={t("CardOrder.billingInformation.formButtonText")}
                                                      containerClassName={"cco_menu_item"}
                                                      
                                                      buttonStyle={{
                                                        backgroundColor: "#c67b05",
                                                        color: "#ffffff",
                                                        fontFamily: '"DMSans Bold"',
                                                        fontSize: "18px",
                                                        fontWeight: "700",
                                                        lineHeight: "1",
                                                        textAlign: "center",
                                                        display: "inline-flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        maxWidth: "100%",
                                                        width: "100%",
                                                        padding: "10px",
                                                        border: "none",
                                                        opacity: isValidated
                                                          ? ".5"
                                                          : "1",
                                                        cursor: isValidated
                                                          ? "not-allowed"
                                                          : "pointer",
                                                      }}
                                                      containerStyle={{
                                                        backgroundColor: "#c67b05",
                                                        color: "#ffffff",
                                                        fontFamily: '"DMSans Bold"',
                                                        fontSize: "18px",
                                                        fontWeight: "700",
                                                        lineHeight: "1",
                                                        textAlign: "center",
                                                        display: "inline-flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        maxWidth: "100%",
                                                        minHeight: "42px",
                                                        padding: "0px 0px",
                                                        border: "2px solid #c67b05",
                                                        borderRadius: "21px",
                                                        cursor: isValidated
                                                          ? "not-allowed"
                                                          : "pointer",
                                                      }}
                                                      formHeaderText={t("CardOrder.billingInformation.formHeaderText")}
                                                      authData={{
                                                        apiLoginID: AuthData?.ApiLoginID ||
                                                          "",
                                                        clientKey: AuthData?.ClientKey || "",
                                                      }}
                                                      environment={AuthData?.IsSandbox
                                                        ? "SANDBOX"
                                                        : "PRODUCTION"}
                                                      onSubmit={handleSubmits}
                                                      billingAddressOptions={{
                                                        show: false,
                                                        required: false,
                                                      }}
                                                      errorTextStyle={{
                                                        fontSize: "12px",
                                                      }} />
                                                    {!isCreditInfo &&
                                                      isSubmitting && (
                                                        <p
                                                          style={{
                                                            color: "red",
                                                            textAlign: "center",
                                                            padding: "10px",
                                                          }}
                                                        >
                                                          {t("CardOrder.billingInformation.CreditInfo")}
                                                        </p>
                                                      )}
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <ul className="cco_form_fieldset">
                                          <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                            <label htmlFor="ccoFullName">
                                            {t("CardOrder.billingInformation.FirstName")}{" "}
                                              <span style={{ color: "#db3a3a" }}>
                                                *
                                              </span>
                                            </label>
                                            <Field
                                              type="text"
                                              id="ccoCity"
                                              name="FirstName" />
                                            <ErrorMessage
                                              name={"FirstName"}
                                              component={"div"}
                                              className="cco_form_message cco_form_message_error" />
                                          </li>
                                          <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                            <label htmlFor="ccoFullName">
                                            {t("CardOrder.billingInformation.LastName")}{" "}
                                              <span style={{ color: "#db3a3a" }}>
                                                *
                                              </span>
                                            </label>
                                            <Field
                                              type="text"
                                              id="ccoCity"
                                              name="LastName" />
                                            <ErrorMessage
                                              name={"LastName"}
                                              component={"div"}
                                              className="cco_form_message cco_form_message_error" />
                                          </li>
                                          <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                            <label htmlFor="ccoEmail">
                                            {t("CardOrder.billingInformation.Email")}{" "}
                                              <span style={{ color: "#db3a3a" }}>
                                                *
                                              </span>
                                            </label>
                                            <Field
                                              type="text"
                                              id="ccoCity"
                                              name="BillToEmail" />
                                            <ErrorMessage
                                              name={"BillToEmail"}
                                              component={"div"}
                                              className="cco_form_message cco_form_message_error" />
                                          </li>
                                          <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                            <label htmlFor="ccoPhoneBilling">
                                            {t("CardOrder.billingInformation.Phone")}{" "}
                                              <span style={{ color: "#db3a3a" }}>
                                                *
                                              </span>
                                            </label>

                                            <InputMask
                                              type={"tel"}
                                              id={"ccoPhoneBilling"}
                                              mask="+1 (999) 999-9999"
                                              value={values.BillingPhone}
                                              onChange={handleChange(
                                                "BillingPhone"
                                              )}
                                              onBlur={handleBlur("BillingPhone")} />

                                            <ErrorMessage
                                              component={"div"}
                                              name={"BillingPhone"}
                                              className="cco_form_message cco_form_message_error" />
                                          </li>
                                          <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                            <label htmlFor="ccoBillingCountry">
                                            {t("CardOrder.billingInformation.Country")}{" "}
                                              <span style={{ color: "#db3a3a" }}>
                                                *
                                              </span>
                                            </label>
                                            <Field
                                              type="text"
                                              id="ccoBillingCountry"
                                              name="BillingCountry" />
                                            <ErrorMessage
                                              name={"BillingCountry"}
                                              component={"div"}
                                              className="cco_form_message cco_form_message_error" />
                                          </li>

                                          <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                            <label htmlFor="ccoFullName">
                                            {t("CardOrder.billingInformation.Address")}{" "}
                                              <span style={{ color: "#db3a3a" }}>
                                                *
                                              </span>
                                            </label>
                                            <Field
                                              type="text"
                                              id="ccoCity"
                                              name="Address" />
                                            <ErrorMessage
                                              name={"Address"}
                                              component={"div"}
                                              className="cco_form_message cco_form_message_error" />
                                          </li>
                                          <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                            <label htmlFor="ccoCity">
                                            {t("CardOrder.billingInformation.City")}{" "}
                                              <span style={{ color: "#db3a3a" }}>
                                                *
                                              </span>
                                            </label>
                                            <Field
                                              type="text"
                                              id="ccoCity"
                                              name="City" />
                                            <ErrorMessage
                                              name={"City"}
                                              component={"div"}
                                              className="cco_form_message cco_form_message_error" />
                                          </li>

                                          <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                            <label htmlFor="ccoState">
                                            {t("CardOrder.billingInformation.State")}{" "}
                                              <span style={{ color: "#db3a3a" }}>
                                                *
                                              </span>
                                            </label>
                                            <Field
                                              type="text"
                                              id="ccoCity"
                                              name="State" />
                                            <ErrorMessage
                                              name={"State"}
                                              component={"div"}
                                              className="cco_form_message cco_form_message_error" />
                                          </li>
                                          <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                            <label htmlFor="ccoFullName">
                                            {t("CardOrder.billingInformation.Zip")}{" "}
                                              <span style={{ color: "#db3a3a" }}>
                                                *
                                              </span>
                                            </label>
                                            <Field
                                              type="text"
                                              id="ccoCity"
                                              name="Zip" />
                                            <ErrorMessage
                                              name={"Zip"}
                                              component={"div"}
                                              className="cco_form_message cco_form_message_error" />
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="cco_form_row">
                                    <div className="cco_form_col">
                                      {!isSubmitting && (
                                        <>
                                          {createCardOrder.data?.success && (
                                            <div
                                              className="cco_form_message cco_form_message_success"
                                              style={{
                                                fontSize: 16,
                                                marginBottom: 20,
                                              }}
                                            >
                                              <strong>
                                                {t("CardOrder.billingInformation.requestSuccess")}
                                              </strong>
                                            </div>
                                          )}

                                          {createCardOrder.error && (
                                            <div
                                              className="cco_form_message cco_form_message_error"
                                              style={{
                                                marginBottom: 20,
                                              }}
                                            >
                                              {(createCardOrder.error as Error)
                                                .message}
                                            </div>
                                          )}
                                        </>
                                      )}
                                      <ul className="cco_menu cco_menu_7">
                                        {/* <li className="cco_menu_item">
                <button
                  className="cco_form_submit"
                  type={"button"}
                  onClick={() => {
                 
                        setSteps(0)
          
                    
                  
                  }}
                  style={{
                    cursor:"pointer"
                  }}
                >
                  Back
                </button>
              </li> */}
                                        <li className="cco_menu_item">
                                          <button
                                            className="cco_form_submit"
                                            disabled={!(isValid && dirty) && !isCreditInfo}
                                            type={"submit"}
                                            style={{
                                              opacity: isValid && dirty && isCreditInfo
                                                ? "1"
                                                : ".5",
                                              cursor: isValid && dirty && isCreditInfo
                                                ? "pointer"
                                                : "not-allowed",
                                            }}
                                          >
                                            {createCardOrder.isLoading ||
                                              (createCardOrder.isSuccess &&
                                                isCreditInfo)
                                              ? "Processing"
                                              : `${t("CardOrder.billingInformation.payButton")} $` + productData.ProductPrice}
                                          </button>
                                        </li>

                                      </ul>
                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          )}
                        </form>
                      )}
                    </Formik>
                  </></>

)}
        

            </div>
          </article>
        </div>
      </div>

      <BlockUI
        title={t("CardOrder.loadingMessage")}
        blocking={createCardOrder.isLoading || createCardOrder.isSuccess && !isModalOpen}
      />
          <TransactionSuccessModal 
              isModalOpen={isModalOpen}
              url='/dashboard'
              toggleModal={() => setIsModalOpen(!isModalOpen)}

            />
    </>
  );
};
