import Modal from 'react-modal';
import logo from '../../assets/success.png'
import { useTranslation } from 'react-i18next';


interface TransactionSuccessModalProps {
    isModalOpen: boolean;
    url: string;
    toggleModal: () => void;
}

export const TransactionSuccessModal = ({ isModalOpen, url, toggleModal }: TransactionSuccessModalProps) => {
      const { t, i18n } = useTranslation();
    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={toggleModal}
            shouldCloseOnOverlayClick={false}
            
            contentLabel="Transaction Success"
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0 20px',
                },
                content: {
                    position: 'relative',
                    width: '400px',
                    padding: '20px',
                    borderRadius: '8px',
                    inset: 'unset',
                    backgroundColor: 'white',
                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                    textAlign: 'center',
                },
            }}
        >
            {/* <button
                onClick={toggleModal}
                style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    cursor: 'pointer',
                    border: 'none',
                    background: 'transparent',
                    fontSize: '1.5rem',
                }}
                aria-label="Close"
            >
                &#10005;
            </button> */}

            <h1 style={{ marginBottom: '15px' }}>{t("TransactionModal.transactionComplete")}</h1>

            <p style={{ marginBottom: '15px' }}>
                <img src={logo} alt="Checkmark" style={{ width: '80px', height: '80px' }} />
            </p>

            <button
                onClick={()=>{
                    toggleModal();
                    window.location.href = url;
                }}
                style={{
                     paddingLeft: '10px',
                     paddingRight: '10px',
                    backgroundColor: '#c67b05',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                }}
            >
                {t("TransactionModal.transactionButton")}
            </button>
        </Modal>
    );
};
