import  { useState } from 'react';
import {  Root } from '../../types/dashboard';
import { formatAddress } from '../../tools/utils';
import { AccessControl } from '../../hooks/Helper/GlobalContext';
import { UseQueryResult } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../components/Loader';

interface ProfileCardProps {
    CandidateAccessControl?:string,
    query:UseQueryResult<Root, Error>,
    data:Root['data']
}

export const ProfileCard = ({CandidateAccessControl,query,data}: ProfileCardProps) => {

    const [profilePictureLoading, setprofilePictureLoading] = useState(true);

    const address = (data?.Addresses?.length || 0) > 0 ? formatAddress(data.Addresses[0]) : null;
    const { t } = useTranslation();


    const handleImageLoaded = () => {
        setprofilePictureLoading(false);
      };


    return (
<div className="cco_row cco_row_0">
                            {query.isLoading ? (
                                <Loader />
                            ) : query.error ? (
                                <p>{query.error.message}</p>
                            ) : (
                                <>
                                    <div className="cco_col cco_col_4">
                                        <div className="cco_img cco_img_1">
                                            <a
                                                className="cco_img_wrap"
                                                href={
                                                    data.ContactPhotoRequest.length > 0 || CandidateAccessControl===AccessControl.Suspended ? '#' : '/photo-management'
                                                }
                                             onClick={(e)=>{
                                                
                                        data.ContactPhotoRequest.length > 0 || CandidateAccessControl===AccessControl.Suspended?e.preventDefault():window.location.href='/photo-management'
                                            }}   
                                            >
                                                {data.ContactPhotoRequest.length <= 0 && !data.myCCOPicture && (
                                                    <>
                                                        <img
                                                            src={"/Missing_Logo.png"}
                                                            alt=""
                                                        />
                                                        <div className="cco_img_icon">
                                                            <span className="cco_img_icon_path_0"></span>
                                                            <span className="cco_img_icon_path_1"></span>
                                                            <span className="cco_img_icon_path_2"></span>
                                                        </div>
                                                    </>
                                                )}
                                                <div
                                                    className={
                                                        data && data.ContactPhotoRequest.length > 0
                                                            ? 'cco_img_caption_pending'
                                                            : 'cco_img_caption'
                                                    }
                                                >
                                                    {data.ContactPhotoRequest.length > 0
                                                        ? t("dashboard.profileCard.pendingApproval")
                                                        : t("dashboard.profileCard.updatePhoto")}
                                                </div>
                                                <div
                                                    className={
                                                        data && data.ContactPhotoRequest.length > 0
                                                            ? 'cco_img_caption_pending cco_img_caption_hover'
                                                            : 'cco_img_caption cco_img_caption_hover'
                                                    }
                                                >
                                                    {data.ContactPhotoRequest.length > 0
                                                        ? t("dashboard.profileCard.pendingApproval")
                                                        : t("dashboard.profileCard.updatePhoto")}
                                                </div>

                                                {data.ContactPhotoRequest.length <= 0 || data.myCCOPicture ? (
                                                    <>
                                                        {profilePictureLoading && (
                                                            <div style={{ marginTop: '30%' }}>
                                                                <Loader />
                                                            </div>
                                                        )}
                                                        <img
                                                            src={data && data.myCCOPicture}
                                                            onLoad={handleImageLoaded}
                                                            style={{
                                                                display: profilePictureLoading ? 'none' : 'block',
                                                            }}
                                                            alt="user profile picture"
                                                        />

                                                        {/* {data && data.ContactPhotoRequest.length<=0 &&
                    <div className="cco_img_caption cco_img_caption_hover">Upload Photo</div>
                    }   */}
                                                    </>
                                                ) : (
                                                    <>
                                                        <img
                                                              src={"/Missing_Logo.png"}
                                                            alt=""
                                                        />

                                                        <div className="cco_img_icon">
                                                            <span className="cco_img_icon_path_0"></span>
                                                            <span className="cco_img_icon_path_1"></span>
                                                            <span className="cco_img_icon_path_2"></span>
                                                        </div>
                                                        <div className="cco_img_caption">
                                                            {data.ContactPhotoRequest.length > 0
                                                                ? t("dashboard.profileCard.pendingApproval")
                                                                : t("dashboard.profileCard.updatePhoto")}
                                                        </div>
                                                    </>
                                                )}
                                            </a>
                                        </div>
                                    </div>

                                    {/* <div className="cco_col cco_col_5"> */}
                                    <div className="cco_col cco_col_12">
                                        <div className="cco_text cco_text_1" style={{fontWeight:'700'}}>
                                            <div className="cco_text_inner">
                                                <h1 >
                                                    <strong>{data.Name.FullName}</strong>
                                                    <span className="divider" />
                                                    <small>
                               {t('dashboard.profileCard.ccoId')} <strong>{data.SecondaryKeys[0].Key}</strong>
                            </small>
                                                </h1>
                                            </div>
                                        </div>
                                     
                                    </div>
                                </>
                            )}
                        </div>
    );
};
