const Spanish = {
    translation: {
      header: {
        roleManagement: "Gestión de Roles",
        testingPersonel: "Personal de Pruebas",
        accountManagement: "Gestión de Cuentas",
        accountDetails: "Detalles de la Cuenta",
        signout: "Cerrar sesión",
        loginRegister: "Iniciar sesión/Registrarse",
        Login:"Iniciar sesión"
      },
      sidebar: {
        dashboardLink: "Tablero",
        contactUsLink: "Contáctanos",
        websiteLink: "Sitio web",
        newsLink: "Noticias CCO",
       resourcesLink: "Recursos",
        storeLink: "Tienda CCO"
      },
      dashboard: {
        profileCard: {
          pendingApproval: 'APROBACIÓN PENDIENTE',
          updatePhoto: 'Actualizar Foto',
          ccoId: 'CCO ID',
          address: 'Dirección',
          phone: 'Teléfono',
          email: 'Correo electrónico'
        },
        myCertificationCard:{
          MyCertifications: "Mis Certificaciones",
          Status:"Estado",
          ExpDate:"Fecha de Expiración",
          noCertifications:'No hay certificaciones disponibles',
          cardOrder: "Tarjeta de pedido",
          cardOrderHover: "Enviar foto para solicitar la tarjeta.",

          Statuses: {
            Active: "Activo",
            Suspended: "Suspendido",
            Revoked: "Revocado",
            Inactive: "Inactivo",
            Approved: "Aprobado",
            Pending: "Pendiente",
            PendingRecert: "Recert Pendiente",
            Expired: "Expirado"
          },
          shareModal: {
            title: "Compartir Mis Certificaciones CCO",
            copyButton: "Copiar",
            shareButton:"Compartir",
            shareSubject: "{{name}} está compartiendo sus certificaciones CCO",
            shareMessage: "{{name}} está compartiendo sus certificaciones CCO contigo. Haz clic aquí para ver su estado de certificación actual: {{url}}",
            copiedMessage: "¡Enlace copiado al portapapeles!"
          }
          
        },
        examHub: {
          heading: "myCCO Centro de Exámenes",
          title: "Dentro del Hub de myCCO, puedes:",
          item1: "Encontrar ubicaciones de pruebas escritas y prácticas",
          item2: "Ver tus exámenes disponibles para programación",
          item3: "Programar/Cambiar citas de exámenes",
          item4: "Ver tus resultados de exámenes",
          ExamHubButton: "Lanzar myCCO Centro de Exámenes"
        },
        welcomeUser: "Bienvenido al myCCO",
        login: "Iniciar sesión",
        hello: "Hola {{name}},",
        applyNow: "Aplicar ahora para",
        learnLinkLabel: "Conocer las opciones de pruebas escritas",
        writingTestButton: "Pruebas Escritas",
        practicalTestButton: "Pruebas Prácticas",
        candidateResources: "Recursos para Candidatos",
        demoExamHeading: "Prueba el examen de demostración de CCO",
        demoExamDescription: "Para que estés preparado el día del examen, familiarízate con el sistema de exámenes de CCO. Puedes conocer el diseño del examen, probar la calculadora en pantalla, navegar una tabla de carga en pantalla, y más.",
        demoExamButton: "Lanzar Examen de Demostración",
        heading: "¿Optando por pruebas OPT o EOT? ¡Haz una prueba de manejo!",
        paragraph: "Familiarízate con el nuevo sistema de exámenes de CCO. CCO ha creado un examen demo gratuito que te permite navegar por el sistema de exámenes y probar la calculadora en pantalla, navegar la tabla de carga, revisar/marcar preguntas y más.",
        certificate: "Mis Certificaciones",
        para2: "No se encontraron certificaciones",
        schedule: "Próximos Exámenes / Resultados Pendientes",
        para3: "No se encontraron próximos exámenes",
        para4: "*Los resultados de los exámenes están sujetos a determinaciones de validez y otras correcciones apropiadas por parte de CCO.",
        para5: "No se encontraron historiales de exámenes",
        head2: "Historial de Exámenes *",
        h2detail: "últimos 12 meses",
        button1: "Pruebas Escritas - EOT/OPT"
      },
      account: {
        incompleteProfile: "Completa tu perfil ahora para acceder a tu CCO ID y enviar una solicitud de examen.",
        pageHeading: "Detalles de la Cuenta",
        personalInfo: "1. Información Personal",
        employeeInfo: "2. Información del Empleador",
        personalForm: {
          fullName: "Nombre Completo",
          dob: "Fecha de Nacimiento",
          email: "Correo Electrónico",
          mobilePhone: "Teléfono Móvil",
          street1: "Calle 1",
          street2: "Calle 2",
          country: "País",
          noCountry: "No se encontraron países. Por favor, inténtalo de nuevo más tarde.",
          city: "Ciudad",
          state: "Estado",
          selectCountry: "Selecciona un país primero",
          noState: "No se encontraron estados. Por favor, inténtalo de nuevo más tarde.",
          selectState: "Selecciona un estado",
          selectaCountry: "Selecciona un país",
          zip: "Código Postal",
          language:'Idioma Preferido',
          selectLanguage:'Seleccionar Idioma',
          selectLanguageFirst:'Selecciona un idioma primero',
        },
        employeeForm: {
          organization: "Organización",
          phone: "Teléfono",
          address1: "Dirección 1",
          address2: "Dirección 2",
          country: "País",
          noCountry: "No se encontraron países. Por favor, inténtalo de nuevo más tarde.",
          city: "Ciudad",
          zip: "Código Postal"
        },
       successMessage: "Tu cuenta se actualizó con éxito, haz clic en el botón Volver al Tablero para continuar.",
        saving: "Guardando...",
        saveChangesButton: "Guardar Cambios",
        returnDashboardButton: "Volver al Tablero"
      },
      testingPersonnel:{
        pageHeading:"Testing Personnel",
        ccoId:"CCO ID",
        noCredential:"No Credentials available",
        credentialJourneyButton:"Start Credential Journey",
        availableCredentialButton:"View all Available Credentials",
        shareModal: {
          title: "Compartir credenciales de myCCO",
          shareButton: "Compartir",
          copyButton: "Copiar",
          shareSubject: "{{name}} está compartiendo sus credenciales de CCO",
          shareMessage: "{{name}} está compartiendo sus credenciales de CCO contigo. Haz clic aquí para ver el estado actual de sus credenciales: {{url}}",
          copiedMessage: "¡Enlace copiado al portapapeles!",
        }
        

      },
      myCredential:{
        breadcrumbsLink:"Testing Personnel",
        status:"Status",
        expiryLabel:"Training Due",
        noExpiry:"Not Available",
        pendingAuditMessage:"Contact CCO to schedule your audit.",
        pendingCertification:"You must complete your certification in order to earn your credential.",
        renewButton:"Renew Now",
        workshopTitle:"Workshops",
        startEnd:"Start Date/Time",
        name:"Name",
        launchLink:"LaunchLink",
        location:"Location",
        noWorkshop:"No workshop applications available",
        description:"Description",
        manageMyTestAdministrations:"Manage My Test Administrations",
        Resources:"Resources",
        paperPencilTesting:"Written Testing - PPT",
        Statuses: {
          Pending: "Pending",
              PendingAudit: "Pending Audit",
              Inactive: "Inactive",
              PendingCertification: "Pending Certification",
              Approved: "Approved",
              Expired: "Expired",
              HOLD: "Hold",
              Retired: "Retired",
              PendingRenewal: "Pending Renewal"
            },
            workshopStatuses: {
              Active: "Active",
              Inactive: "Inactive",
              Pending: "Pending",
              Approved: "Approved",
              Attended: "Attended",
              Completed: "Completed"
            }
      },
      Credential:{
        testingPersonel:'Testing Personnel',
        JourneyStatus:'  Journey Status',
        PreReq:{
        WrittenExamText1 :'Written Exam Required',
        WrittenExamText2 :'Click here to sign up',
        ExamStatus : 'Status',
        ExamTestDate : 'Test Date',
        CertificationText1 :'Certification Required',
        CertificationText2 :'Click here to sign up',
        CertExpirationDate :'Expiration Date',
        PreReqStatus:'Status:',
        PreReqExpirationDate : 'Expiration Date: ',
        NotPurchased : ' Not Purchased',
        PreReqPara : 'There are no pre-requisites for',
         PreReqPara2: 'credential.',
        },
        BillingInfo:{
         billinginfo : 'Billing Information',
         billingNote :'Note: We Accept VISA, MasterCard, and American Express.',
         CardNumber : 'Credit Card Number',
         TypeCreditCard : 'Credit Card',
         Error:'Something went wrong',
         CloseWorkshopPara :'No closed workshop available against this',
         CloseWorkshopPara2 :'access code.',
         Process :'Processing',
         Pay :'Pay $',
         AccessCodefield:'Enter access code',
         AccessCode:'Access Code',
         Loading:'Loading',
         billingbuttonText :'Click here to fill Credit Card Information',
         formbuttonText : 'Continue',
         formHeaderText :'Payment',
         Cardinfo: ' Please fill credit card information!',
         FirstNamefield :' First Name',
         LastNamefield :' Last Name',
         Emailfield : ' Email',
         Phonefield : 'Phone',
         Countryfield : 'Country',
         Addressfield: 'Address',
         Cityfield:'City',
         Statefield: 'State',
         Zipfield :'Zip',
         SuccessResponse :'Request submited successfully.',
        },
         
         CredentialInfo :'Credential Information',
         CredentialEmpty : 'Credential information is empty',
         Workshop:{
         AvailableWorkshop: 'Available Workshops',
         WorkshopError: 'You must select one workshop to proceed.',
         Startdate:'Start Date/Time*',
         Enddate: 'End Date/Time*',
         Location:'Location',
         AvailableSeats :'Available Seats',
         ApplicationDeadline :'Application Deadline',
         AuditEvent : 'Audit Event',
         WorkshopPara1: ' Sorry, there are currently no available workshops. You are not able to continue until a workshop becomes available. If you`d like to be added to the waitlist, please email',
         WorkshopPara2:'Provide your name, CCO ID, and indicate which workshop you are interested in attending. We will notify you when additional workshops are added.',
         },
         Agreement :'Agreement',
         termsandCondition : '  I understand and agree to this Agreement. I further understand and agree that a payment to register or enroll in any testing personnel training (such as a course or a workshop) cannot be refunded once my request and payment are submitted to CCO. In addition, I understand that  any such registration cannot be cancelled or transferred. By submitting a payment, I agree to CCO `s',
         FinancialTerms :' Financial Terms and Conditions',
         ProceedtCheckout :' Proceed to checkout',
         Submit:'Submit',
         Title:'Please wait while payment is processed. Do not refresh page or click back button.'
        },
        CredentialRenewal:{
          testingPersonel:'Testing Personnel',
          JourneyStatus:'  Journey Status',
          WrittenExamText1 :'Written Exam Required',
          WrittenExamText2 :'Click here to sign up',
          ExamStatus : 'Status',
          ExamTestDate : 'Test Date',
          CertificationText1 :'Certification Required',
          CertificationText2 :'Click here to sign up',
          CertExpirationDate :'Expiration Date',
          PreReqStatus:'Status:',
          PreReqExpirationDate : 'Expiration Date: ',
          NotPurchased : ' Not Purchased',
          PreReqPara : 'There are no pre-requisites for',
           PreReqPara2: 'credential.',
           billinginfo : 'Billing Information',
           billingNote :'Note: We Accept VISA, MasterCard, and American Express.',
           CardNumber : 'Credit Card Number',
           TypeCreditCard : 'Credit Card',
           Error:'Something went wrong',
           CloseWorkshopPara :'No closed workshop available against this',
           CloseWorkshopPara2 :'access code.',
           Process :'Processing',
           Pay :'Pay $',
           AccessCodefield:'Enter access code',
           AccessCode:'Access Code',
           Loading:'Loading',
           billingbuttonText :'Click here to fill Credit Card Information',
           formbuttonText : 'Continue',
           formHeaderText :'Payment',
           Cardinfo: ' Please fill credit card information!',
           FirstNamefield :' First Name',
           LastNamefield :' Last Name',
           Emailfield : ' Email',
           Phonefield : 'Phone',
           Countryfield : 'Country',
           Addressfield: 'Address',
           Cityfield:'City',
           Statefield: 'State',
           Zipfield :'Zip',
           SuccessResponse :'Request submited successfully.',
           CredentialInfo :'Credential Information',
           CredentialEmpty : 'Credential information is empty',
           AvailableWorkshop: 'Available Workshops',
           WorkshopError: 'You must select one workshop to proceed.',
           Startdate:'Start Date/Time*',
           Enddate: 'End Date/Time*',
           Location:'Location',
           AvailableSeats :'Available Seats',
           ApplicationDeadline :'Application Deadline',
           AuditEvent : 'Audit Event',
           WorkshopPara1: ' Sorry, there are currently no available workshops. You are not able to continue until a workshop becomes available. If you`d like to be added to the waitlist, please email',
           WorkshopPara2:'Provide your name, CCO ID, and indicate which workshop you are interested in attending. We will notify you when additional workshops are added.',
           Agreement :'Agreement',
           termsandCondition : '  I understand and agree to this Agreement. I further understand and agree that a payment to register or enroll in any testing personnel training (such as a course or a workshop) cannot be refunded once my request and payment are submitted to CCO. In addition, I understand that  any such registration cannot be cancelled or transferred. By submitting a payment, I agree to CCO `s',
           FinancialTerms :' Financial Terms and Conditions',
           ProceedtCheckout :' Proceed to checkout',
           Submit:'Submit',
           Title:'Please wait while payment is processed. Do not refresh page or click back button.',
           Renew :'Renew',
          AuthorizedError:'Our records indicate that you are not authorized to access this page. Please contact CCO if you have any questions. ',
          NotRenewed :' Not Renewed',
         NotAvailable : 'Not Available',
         ProductError:'Credential product fee not found!'
        }
      ,credentialjourney:{
        CredentialJourney: "Credential Journey",
        testingPersonnel:"Testing Personnel",
        SelectJourneyButton :"Select your Credential journey",
      },
      CAD:{
        CadManagement:{
        pageHeading:"Testing Personnel",
        ccoId:"CCO ID",
        PracLocations:'Practical Locations',
        CustomCaD:'My Custom CADs',
        BacktoPrac:'Back to Prac Test Site',
        AddnewCad:'Add new Custom CADs',
        PendingCad:'My Pending Review Custom CADs',
        ApproveCad:'My Approved Custom CADs',
        CompleteCad:'My Completed Custom CADs',
        ExpireCad:'My Expired Custom CADs',
        NoCustomCad:'No Custom CADs found',
        },
        BillingInformation:{
        billinginfo : 'Billing Information',
        billingNote :'Note: We Accept VISA, MasterCard, and American Express.',
        CardNumber : 'Credit Card Number',
        TypeCreditCard : 'Credit Card',
        Error:'Something went wrong',
        Process :'Processing',
        Pay :'Pay $',
        billingbuttonText :'Click here to fill Credit Card Information',
        formbuttonText : 'Continue',
        formHeaderText :'CAD Payment',
        Cardinfo: 'It`s compulsory to fill credit card information first',
        FirstNamefield :' First Name',
        LastNamefield :' Last Name',
        Emailfield : ' Email',
        Phonefield : 'Phone',
        Countryfield : 'Country',
        Addressfield: 'Address',
        Cityfield:'City',
        Statefield: 'State',
        Zipfield :'Zip'
      },
       CADlanding:{
        OptionYes:'Yes',
        OptionNo :'No',
        descrip1: 'Is your test weight anything other than 3 ft. outside diameter?',
        for1:"For All Mobile Cranes:",
        descrip2: "Does your crane have rear outrigger spread of greater than 24 ft.?",
        for2:"For Boom Trucks:",
        descrip3: "Does your crane have less than 42 ft. of main boom (jibs not permitted)?",
        descrip4: "Does your crane operate only with a remote control?",
        descrip5: "Does your crane have less than 30 ft. of main boom?",
        for5:"For Carry Deck Cranes:",
        descrip6: "Does your crane have less than 50 ft. of main boom?",
        for6:"For Telescopic Boom Cranes (Carry Deck exempt):" ,
    },
        CustomCAD:{
        pageHeading:"Testing Personnel",
        OptionYes:'Yes',
        OptionNo :'No',
        TscPrac:'TSC-Practical',
        NewCustom:'New Custom CAD',
        CustomCAD:'Custom CAD',
        CADDescription :'CAD Description',
        CADQuestion:'Use the questions below to determine if a custom CAD is required.',
        CraneType:'To proceed with Custom CAD, select the Crane type:',
        Next:'Next',
        CADinfo:'Information',
        Makefield:'Make',
        Modelfield:'Model',
        SerialNumberfield :'Serial Number',
        MaxRelatedfield :'Max Related Capacity(Tons)',
        LATfield:'What boom length can you set that is between 70-90ft or 110-130ft of main boom?',
        otherfield:'What is the maximum main boom length?',
        Truckfield:'Is this a boom truck?',
        SelectOption:' Select an option',
        OptionNull:'N/A',
        Workingareafield:'Working area (degrees)',
        degree:'degree',
        Cranefield:'Crane Position',
        behindOption:'Behind cab',
        CenterOption:'Center mount',
        RearOption:'Rear mount',
        FifthOption :'Fifth wheel',
        Truckfield :'Truck Bed Length (ft)',
        FrontOutriggerfield :'Front Outrigger/Stablizer Spread(ft)',
        BackOutriggerfield:'Back Outrigger/Stablizer Spread(ft)',
        RopeSizefield:'Rope Size & Type',
        Allowablefield:'Allowable line pull as stated in the load chart (lb)',
        Diameterfield:'Diameter of test weight (ft) ' ,
        UploadLoadChart :'Upload Load Chart Documentation',
        ClicktoUpload:'Click to Upload',
        DownloadChart:'Download Load Chart',
        UploadLinePullChart:'Upload Line Pull Chart',
        DownloadLinePull:'Download Line Pull',
        UploadRangeDiagram:' Upload Range Diagram',
        DownloadRange:' Download Range Diagram',
        DeliveryType:'Select which type of delivery method you would like to purchase:',
        StandardType:'- Standard Custom CAD delivery is 5-7 Business Days.',
        RushType:' - Rush Delivery 2-3 Business Days.',
        SuccessError:"Successfully added Custom CAD.",
        Title:'Please wait while payment is processed. Do not refresh page or click back button.'
        }
      },

      AvaliableCredential:{
        pageHeading:"Testing Personnel",
        AvailableCredentials:'Available Credentials',
        NoCredentials :'No Credentials available',

      },
      NewLocation:{
        Attestation:{
    entry: 'Only Testing Room, with a single point of entry/exit during testing',
    proctor: 'One Proctor Workstation, located in the testing room',
    stations: 'One candidate testing station',
    parking: 'Adequate Parking for the number of vehicles anticipated',
    restroom: 'Restroom is available on-site',
    equipment: 'Candidate/equipment spacing in accordance with CCO policy',
    Attestation:'Attestation',
    Completed:"Completed",
    InProgress:"In Progress",
    FillFields:'Please fill out all fields',
    View:'View',
    NothingSelected:'Nothing selected',
    Edit:'Edit',
    station:'Testing stations and equipment in accordance with CCO policy',
    proctors:'Dedicated proctor station located in testing room',
    entrys:'A dedicated, secure testing room, with controlled entry/exit during testing',
    Req:'Do you have the following requirements?'
        },
        newlocations:{
          Nextstep:"Next step",
          Goback:"Go back",
          Previousstep:"Previous step",
          SubmitApplication:"Submit Application",
          note:"I understand and agree that an application to register a test site cannot be cancelled or transferred, and payments for application fees cannot be refunded, once the application and payment are submitted to CCO. I also declare that the information contained in this application, as well as the information in any required accompanying documentation, is true and correct. I understand that insufficient or inadequate information may result in delays in processing. By submitting a payment, I agree to CCO's",
          FinancialTermsandConditions:"Financial Terms and Conditions",
          AddEOTTestSite:"Add EOT Test Site",


          EOT:"EOT Test Site",
          Adding:"Successfully added the new location",
          saei:'Save & Exit',
          sa:'Save changes'



        },
        Summary:{
          Summary:"Summary"

        },
        BillingInfo:{
          Payment:'Payment',
          FillFields:'Please fill out all fields',
          View:'View',
          CardNumber :'Card Number',
          Completed:"Completed",
          Amount:'Amount',
          FirstName :'First Name',
          LastName:'Last Name',
          Email:'Email',
          Phone:'Phone',
          Address:'Address',
          Country:'Country',
          City:'City',
          Zip:'Zip',
          State:'State',
          Edit:'Edit',
          Cardinfo: 'It`s compulsory to fill credit card information first',
          formHeaderText :'Location Payment',
          billingbuttonText :'Click here to fill Credit Card Information',
          formbuttonText : 'Continue',
          billinginfo : 'Billing Information',
          billingNote :'Note: We Accept VISA, MasterCard, and American Express.',
          CardNumber : 'Credit Card Number',
          TypeCreditCard : 'Credit Card',
          Instruction:'Instructions: Complete the following page using the credit card holder’s information and billing address.'
        },
      
        TestingFacilityincludingSignage:{
          Photo:"2. Photo of Testing Facility including Signage",
          upload:"Upload Testing Facility Photo",
          picdetail:"5MB max – only .jpeg/.jpg, .png, or .pdf file types supported",
          upload:"Click Here to Upload",
          sampletesting:"Sample Testing Facility Photo",

        },
        TestingSite:{
          TestSiteCapacity :'Test Site Capacity',
          FillFields:'Please fill out all fields',
          InProgress:"In Progress",
          View:'View',
          
          Edit:'Edit',
          NoAnswer:'Not answered',
          Requir:'Do you have the minimum download/upload speed required?',
          Testingstations :'Testing stations',
          Completed:"Completed",
          NotCompleted:'Not completed',
          hA:'EOT Test Site Internet Requirements (Minimum)',
          para1A:' Testing Facilities must be able to meet the following technical requirements to be approved for EOT testing:',
          list1:'Non-Cellular Internet Source (e.g., broadband, cable)',
          list2:'Recommended download speed of 12 Mbps per device (minimum 2 Mbps)',
          list3:'Recommended upload speed of 3 Mbps per device (minimum 2 Mbps)',
          Note1A:'Note: Cellular/mobile hot spots or cellular MiFi devices are not supported.',
          list4:'Dedicated internet network',
          list5:'Recommended: hard-wired internet network (Ethernet)',
          list6:'Minimum: Wireless Internet Network (WiFi)',
          list7:'Private and Password Protected',
          list8:'Firewalls Disabled',
          Note2A:'Note: Public or shared networks are not supported (e.g., hotel public WiFi)..',
          para2A:'Review the full technical requirements as found in the Test Administration Handbook – EOT Testing, and access online tools to measure the internet speed – ',
          click:'click here',
          para3A:' Don’t know your internet speed? Contact testing facility IT support or run a speed test. This link will allow you to run a live speed test:',
          link:"https://www.speedtest.net/",
          performthetest:"Make sure you perform the test in the testing room you plan to use",
          capacity:"B.	Test Site Capacity",
          factors:"Based on all factors, including the available internet speed, internet network and any spacing or equipment limitations, how many candidate testing stations is the facility able to support?",
          attestation3:"C.	Attestation Statement",
          conformation:"I have confirmed that this testing facility meets the EOT Test Site Internet Requirements, and that the internet download/upload speeds will support the number of candidate testing stations entered above.",
          option:"Select an option",
          Yes:"Yes",
          No:"No - Yes is required to submit application",

        },
        TestingRoomLayout:{
          roomlayout:"4. Testing Room Layout",
          upload:"Upload Testing Room Layout",
          picdetail:"10MB max – only .jpeg/.jpg, .png, or .pdf file types supported",
          uploadhere:"Click Here to Upload",
          download:"Download Testing Room Layout Form"
        },

        Representation: {
          representativeInformation: "Test Site Representative Information",
          testingFacility:"The Test Site Representative is the Testing Facility Company Representative. This person authorizes the Test Site Coordinator to set up this test site.",
          name: "Full Name",
          phone: "Mobile Phone",
          email: "Email",
          emailconfirmation: "Email Confirmation",
          uploadtsragreement: "Upload TSR Agreement",
          upload:"Click to Upload",
          maxsizemb: "10MB max (pdf)",
          terms_conditions:"The terms & conditions field is required",
          downloadagreement: "Download Test Site Representative Agreement",
        },
        TestSite:{
          testSite:"1. Test Site Information",
          FacilityTest:"Testing Facility Company Name",
          requireName:"The company name field is required",
          Street1 : "Street 1",
          steet1Require:"The street1 field is required",
          street2: "Street 2",
          eg: "(e.g. Suite #, Building #, Room #)",
          country:"Country",
          nocountry:"No countries found. Please try again later.",
          countryRequire: "The country field is required",
          city: "City",
          cityField: "The city field is required",
          state:"State",
          nostates:"No states found. Please try again later.",
          staterequied: "The state field is required",
          zip: "Zip",
          zipRequired: "The zip field is required",
          securefacility: "Is this a secure facility, that has additional access requirements?",
          securerequire:"The secure facility field is required",
          option: "Select an option",
          YES: "Yes",
          NO: "No",
          Timezone: "Timezone",
          timezonerequired: "The timezone field is required",
          notimezone: "No timezone found. Please try again later.",
          Additional: "Please provide additional security instructions that would be required for CCO personnel to access the facility",
          africa:"E. Africa Standard Time",
          hawain: "Hawaiian Standard Time",
          alaskan: "Alaskan Standard Time",
          pacific:"Pacific Standard Time",
          pacificmexico: "Pacific Standard Time (Mexico)",
          us: "US Mountain Standard Time (AZ)",
          mountain: "Mountain Standard Time",
          central: "Central Standard Time",
          eastren: "Eastern Standard Time",
          atlantic: "Atlantic Standard Time",
          west: "West Pacific Standard Time",
          usAz:"US Mountain Standard Time (AZ)",


          selecttz:"Select a timezone",
          selectstate:"Select a state",
          selectcountry:"Select a country",
            
        },
        STestSiteRepresentativeInformation:{
          testsite:"Test Site Representative Information",
          InProgress:"In Progress",
          FullName:"Full Name",
          fill:"Please fill out all fields",
          View:"View",
          Phone:"Phone",
          Email:"Email",
          Completed:"Completed",
          TSRAgreement:"TSR Agreement",
          Edit:"Edit",
          Notcompleted:"Not completed",
          
        },
        LocationDoorSign:{
          LocationDoorSign:"Location Door Sign",
          InProgress:"In Progress",
          fill:"Please fill out all fields",
          View:"View",
          Edit:"Edit",
          Completed:"Completed"
        },
        LocationSitePlan:{
          LocationSitePlan:"Location Site Plan",
          fill:"Please fill out all fields",
          InProgress:"In Progress",
          View:"View",
          Completed:"Completed",
          Edit:"Edit"
        },
        LocationInformation:{
          LocationInformation: "Location Information",
          InProgress:"In Progress",
          fill:"Please fill out all fields",
          View:"View",
          CompanyName:"Company Name",
          Street1:"Street 1",
          Street2:"Street 2",
          City:"City",
          Country:"Country",
          Additionalsecurity:"Additional security",
          Zip:"Zip",
          State:"State",
          Country:"Country",
          Securefacility:"Secure facility ?",
          Edit:"Edit",
          Completed:"Completed",
          Notcompleted:"Not completed",
          waiting:"Waiting..."
        }
      

      },
      GeneratePdf: {
        Sheet  :{ 
          sheet1:"Generate Score Sheet PDFs",
          sheet2: "Generate Signalperson Exam PDF",
          sheet3: "Generate Signalperson CRG PDF"
        },
        Headerpage1:{
          practicalExamScoreSheetDownload: 'Practical Exam Score Sheet Download',
          termsAndConditions: 'By downloading score sheets, you agree to the following terms and conditions:', 
          credentialProctor: 'You are a credentialed Practical Exam Proctor with at least one proctor credential designation.',
          scoreSheetRestrictions: 'You may only download score sheets for credential designations that you possess.',
          approved: 'You are approved to administer CCO practical exams.',
          scoreSheetUsage: 'Each score sheet is serialized and can be used only once. You may print them once, and no copies can be made after printing.',
          expirationDetails: 'Each score sheet has an expiration date (top right corner) and may not be used after its expiration date.',
          destructionPolicy: 'Unused (expired) score sheets must be destroyed, and PDF files must be deleted after use.',
          copyrightProtection: 'You understand that the score sheets are protected by federal copyright law.',
          sharingRestriction: 'You will not share this link or any score sheets with anyone. Only you are allowed to handle or submit practical exam candidate score sheets.',
          secureStorageRequirement: 'You will keep all score sheets in a secured location.',
          deletionRequirement: 'You will delete all downloads or destroy any hard copies of score sheets if required to do so by CCO.',
          consequenceAwareness: 'You understand the consequences as outlined in the Practical Exam Proctor Handbook on the custody, care, and control of the score sheets.',
          sanctionAvoidance: 'You are not acting to circumvent the sanction of another Practical Exam Proctor.',
          examAdministration: 'You agree to administer the exams according to CCO policies and procedures.',
          printingRecommendation: 'It is highly recommended that all score sheets be printed double-sided.',
          acknowledgment: 'I acknowledge that I have read and understood the above policies and procedures in their entirety and agree to abide by them. (This box must be checked to place an order.)',
          label: 'Score Sheet Type *',
          dropdownPlaceholder1: 'Select an option', 
          label2: 'Quantity *',
          dropdownPlaceholder: 'Select an option',
          dropdown1: "ANCHOR/MICROPILE DRILL RIG" ,
          dropdown2: "ARTICULATING BOOM CRANE",
          dropdown3: "ARTICULATING BOOM LOADER",
          dropdown4: "CONCRETE PUMP OPERATOR – CLASS 1",
          dropdown5: "CONCRETE PUMP OPERATOR – CLASS 2",
          dropdown6: "DEDICATED PILE DRIVER",
          dropdown7: "DIGGER DERRICK",
          dropdown8: "FOUNDATION DRILL RIG",
          dropdown9: "MCO-LATTICE BOOM CRANE (LAT)",
          dropdown10: "MCO-TELESCOPIC BOOM-SWING CAB (TLL)",
          dropdown11: " MCO-TELESCOPIC BOOM-FIXED CAB (TSS)",
          dropdown12: " OVERHEAD CRANE OPERATOR",
          dropdown13: "RIGGER LEVEL I",
          dropdown14: "RIGGER LEVEL II",
          dropdown15: "SERVICE TRUCK CRANE OPERATOR",
          dropdown16: "SIGNALPERSON",
          dropdown17: "TELEHANDLER OPERATOR - FIXED",
          dropdown18: "TELEHANDLER OPERATOR - ROTATING",
          dropdown19: "TOWER CRANE OPERATOR ",  
          back: "Back"

          },
          Headerpage2: {
            signalPerson: "SIGNALPERSON EXAM AND CRG DOWNLOAD",
            agreement: "By downloading the Signalperson Practical Exam and Candidate Response Guide, you agree to the following terms and conditions:", 
            credentialPracticalExam: "You are a credentialed Practical Exam Proctor in the Signalperson program.",
            approvalToAdminister: "You are approved to administer Signalperson practical exams.",
            responseGuide: "You understand that the Exam and Candidate Response Guide are protected by federal copyright law.",
            shareLink:"You will not share this link, Exam, or Candidate Response Guide with anyone.",
            CandidateResponse: "You will keep the Exam and Candidate Response Guide in a secured location.",
            copiesOfExam: "You will delete all copies of the Exam and Candidate Response Guide if required to do so by CCO.",
            consequenses: "You understand the consequences as outlined in the Practical Exam Proctor Handbook on the custody, care, and control of the Exam and Candidate Response Guide.",
            practicalExamProtor: "You are not acting to circumvent the sanction of another Practical Exam Proctor.",
            CCOPoliceandProcedure: "You agree to administer the exams according to CCO policies and procedures.",
            acknowledgment: "I acknowledge that I have read and understood the above policies and procedures in their entirety and agree to abide by them."
        }, 
        },

        EOTComponents:{
          EOTComponent:{
          TestingPersonnel:"Testing Personnel",
          TestSiteLocations:"Test Site Locations",
          CCOID:"CCO ID",
          MyEOTTestSites:"My EOT Test Sites",
          AddnewEOTtestsite:"Add new EOT test site",
          Notestsitesfound:"No test sites found",
          MyPendingPaymentEOTTestSites:"My Pending Payment EOT Test Sites",
          MyPendingReviewEOTTestSites:"My Pending Review EOT Test Sites",
          Notestsitesfound: "No test sites found",
          MyApprovedEOTTestSites:"My Approved EOT Test Sites",
          Notestsitesfound:"No test sites found",
          MyExpiredEOTTestSites:"My Expired EOT Test Sites",
          Notestsitesfound: "No test sites found",
          manageMyTestAdministrations:"Manage My Test Administrations"

          },
          Mainpage:{
            ExpireDate:"Expire Date:",
            View:"View",
            CompanyName:"Company Name",
            Address:"Address",
            Status:"Status",
            ExpireDate:"Expire Date",
            Edit:"Edit",
            RenewNow:"Renew Now"
          }
          
        },
        
        Banner:{
          CandidateAccessControl:{
          note: "IMPORTANTE: ¡Necesitamos tu foto! Por favor, sube tu foto de perfil lo antes posible para evitar retrasos en las pruebas o la recepción de tu tarjeta de certificación.",
          red: "Tu cuenta está bloqueada. Si tienes alguna pregunta, por favor contacta con el Servicio al Cliente en candidate@ccocert.org o al 703-560-2391.",
          orange: "Tu cuenta está actualmente suspendida. Algunos datos de la cuenta y relacionados pueden seguir siendo visibles. Si tienes alguna pregunta, por favor contacta con el Servicio al Cliente en candidate@ccocert.org o al 703-560-2391.",
          yellow: "Actualmente debes programar la fecha/hora de tu cita de examen al menos con dos días hábiles de antelación. Si tienes alguna pregunta, por favor contacta con el Servicio al Cliente en candidate@ccocert.org o al 703-560-2391."
          
          },
          testingpersonalBanner:{
            yellow: 'Your testing personnel account is subject to the test administration advanced scheduling requirement. You may only create or be assigned to a test administration with a start date/time at least two business days into the future. Should you have any questions, please contact Testing Personnel Support at testingpersonnel@nccco.org or 703-560-2391 ext. 812.',
            orange: 'Your testing personnel account is currently suspended. Some account and related data may still be visible. Should you have any questions, please contact Testing Personnel Support at testingpersonnel@nccco.org or 703-560-2391 ext. 812.',
            red: 'Your testing personnel account is locked. Should you have any questions, please contact Testing Personnel Support at testingpersonnel@nccco.org or 703-560-2391 ext. 812',
          }
        } ,

        cards:{
          ContactCredentialCard:{
            Status: "Status",
            TrainingDue:"Training Due",
            Statuses: {
              Pending: "Pending",
              PendingAudit: "Pending Audit",
              Inactive: "Inactive",
              PendingCertification: "Pending Certification",
              Approved: "Approved",
              Expired: "Expired",
              HOLD: "Hold",
              Retired: "Retired",
              PendingRenewal: "Pending Renewal"
            }
          }
        },
        NewPracLocation:{
          LocationInfromation:{
            TestingFacilityCompanyName:"Testing Facility Company Name",
            Street1:"Street 1",
            TestSiteInformation:"Test Site Information",
            Street2:"Street 2",
            str2:"(e.g. Suite #, Building #, Room #)",
            Selectacountry:"Select a country",
            Country:"Country",
            noCountry:"No countries found. Please try again later.",
            City:"City",
            State:"State",
            Selectastate:"Select a state",
            noState:"No states found. Please try again later.",
            Zip:"Zip",
            zipreq:"Is this a secure facility, that has additional access requirements?",
            Selectanoption:"Select an option",
            Yes:"Yes",
            No:"No",
            Timezone:"Timezone",
            Selectatimezone:"Select a timezone",
            Notimezone:"No timezone found. Please try again later.",
            provide:"Please provide additional security instructions that would be required for CCO personnel to access the facility"


          },
          newpracloc:{
            PracticalTestSite: "Practical Test Site",
            AddPracticalTestSite: "Add Practical Test Site",
            note:"I understand and agree that an application to register a test site cannot be cancelled or transferred, and payments for application fees cannot be refunded, once the application and payment are submitted to CCO. I also declare that the information contained in this application, as well as the information in any required accompanying documentation, is true and correct. I understand that insufficient or inadequate information may result in delays in processing. By submitting a payment, I agree to CCO's.",
            note2:"Financial Terms and Conditions",
            added:"Successfully added the new location",
            back:'Go back',
            step:'Previous step',
            Submit:"Submit Application",
            next: 'Next step',
            sa:'Save changes',
            saei:'Save & Exit',
            wait:'Updating please wait...',
            plz:'Please wait while submission is processing. Do not click Back or Refresh.',

            
          },
          locationDoorSign:{
            photo:"Photo of Testing Facility including Signage",
            upphoto:"Upload Testing Facility Photo",
            mb:"5MB max",
            upload:"Click Here to Upload",
            sample:"Sample Testing Facility Photo",

            CourseArea:"Practical Testing Course Area Photo",
            uploadphoto:"Upload Photo of Practical Test Area",
            mb:"10MB max",
            upload:"Click Here to Upload",
          },
          Examofferd:{
            PracticalExams :"Practical Exams Offered",
            select:"Please select the CCO practical exams you plan to offer at this test site.",
            note: "I understand and acknowledge that CCO has established unique and specific criteria for each practical exam type and the related equipment to be used for testing, which may include but is not limited to, the testing area size, testing area surface, levelness of the ground, proximity to obstructions and hazards and that the equipment to be used for testing is compliant with all applicable industry and manufacturer standard(s). I attest that I have read and familiarized myself with CCO's criteria for Practical Test Sites, as set forth in the Test Administration Handbook - Practical and agree to prepare this Test Site and related equipment in accordance with the stated requirements. I furthermore attest that the testing course is free of all training aides and training/reference materials."
            
          },
          TestSiteRepresentativeInfo:{
            TestSiteRepresentativeInformation:"Test Site Representative Information",
            note:"The Test Site Representative is the Testing Facility Company Representative. This person authorizes the Test Site Coordinator to set up this test site.",
            FullName:"Full Name",
            MobilePhone :"Mobile Phone ",
            Email:"Email",
            EmailConfirmation:"Email Confirmation",
            UploadTSR:"Upload TSR Agreement",
            mb:"10MB max (pdf)",
            btn:"Click to Upload",
            download: "Download Test Site Representative Agreement"
          },
          Attestation:{
            Attestation:"Attestation",
            req:"Do you have the following requirements?",
            ade:"Adequate parking for the number of anticipated vehicles",
            availible:"Restrooms available on-site",
            pretest:" A pre-test briefing area for candidates waiting to test (unable to observe candidates who are live testing)",

          },
          BillingInformation:{
            BillingInformation:" Billing Information",
            note:"Note: We Accept VISA, MasterCard, and American Express.",
            note2:"It's compulsory to fill credit card information first",
            FirstName:"First Name",
            LastName:"Last Name",
            Email:"Email",
            Phone :"Phone ",
            Country: "Country",
            Address:"Address",
            City:"City",
            State:"State",
            Zip: "Zip",
            btn1:"Click here to fill Credit Card Information",
            Continue:"Continue",
            LocationPayment:"Location Payment"


          }
        },
        SummaryPracSite:{
          LocationInformation:{
            LocationInformation:"Location Information",
          com:"Completed",
          inprog:"In Progress",
          note:"Please fill out all fields",
          View:"View",
          CompanyName:"Company Name",
          Street1:"Street 1",
          Street2:"Street 2",
          City:"City",
          Zip:"Zip",
          State:"State",
          Country:"Country",
          fac:"Secure facility ?",
          sec:"Additional security",
          Notcompleted:"Not completed",
          waiting:"Waiting...",
          Yes:"Yes",
          No:"No",
          Edit:"Edit"
          },
          LocationDoorSign:{
            LocationDoorSign:"Location Door Sign",
            com:"Completed",
            inprog:"In Progress",
            note:"Please fill out all fields",
            sup:"File type not supported",
            View: "View",
            Edit:" Edit",
          },
          PracticalTesting:{
            PracticalTesting:"Practical Testing Course Area Photo",
            com:"Completed",
            inprog:"In Progress",
            note:"Please fill out all fields",
            pracArea:"Photo of Practical Test Area",
            Edit:"Edit"

          },
          PracticalExamOffered:{
            PracticalExamOffered:"Practical Exam Offered",
            com:"Completed",
            inprog:"In Progress",
            View:"View",
            note:"Please fill out all fields",
            Nothingselected:"Nothing selected",
            Attes:"Is Practical Attestation",
            Edit:"Edit"

          },
          TestSite:{
            TestSite:"Test Site Representative Information",
            com:"Completed",
            inprog:"In Progress",
            View:"View",
            note:"Please fill out all fields",
            FullName:"Full Name",
            Phone:"Phone",
            Email:"Email",
            TSR:"TSR Agreement",
            Edit:"Edit",
            notcom:'Not completed'
          },
          Attestation:{
            Attestation:"Attestation",
            com:"Completed",
            inprog:"In Progress",
            View:"View",
            note:"Please fill out all fields",
            no:"Nothing selected",
            Edit:" Edit"
          },
          Payment:{
            Payment:"Payment",
            com:"Completed",
            inprog:"In Progress",
            View:"View",
            note:"Please fill out all fields",
            CardNumber:"Card Number",
            Amount:"Amount",
            FirstName:"First Name",
            LastName:"Last Name",
            Email:"Email",
            Phone:"Phone",
            Address: "Address",
            Country:"Country",
            City:"City",
            State :"State",
            Edit: "Edit"

          },
          Summary:{
            Summary:"Summary"
          }
          
        },

       
//       testingPersonnel: {
//         pageHeading: 'Personal de Pruebas',
//         ccoId: 'ID de CCO',
//         noCredential: 'No hay credenciales disponibles',
//         credentialJourneyButton: 'Comenzar el Trayecto de Credencial',
//         availableCredentialButton: 'Ver Todas las Credenciales Disponibles'
//       },
//       myCredential: {
//         breadcrumbsLink: 'Personal de Pruebas',
//         status: 'Estado',
//         expiryLabel: 'Capacitación Vencida',
//         noExpiry: 'No Disponible',
//         pendingAuditMessage: 'Contacta a CCO para programar tu auditoría.',
//         pendingCertification: 'Debes completar tu certificación para obtener tu credencial.',
//         renewButton: 'Renovar Ahora',
//         workshopTitle: 'Talleres',
//         startEnd: 'Fecha/Hora de Inicio',
//         name: 'Nombre',
//         launchLink: 'LanzarEnlace',
//         location: 'Ubicación',
//         noWorkshop: 'No hay aplicaciones de talleres disponibles',
//         description: 'Descripción',
//         manageMyTestAdministrations: "gestionarMisAdministracionesDePruebasUrl",
//         Resources: "Recursos",
//         paperPencilTesting: "pruebaConLápizYPapel"

//       }
//       ,   Credential: {
//         testingPersonel: 'Personal de Pruebas',
//         JourneyStatus: 'Estado del Viaje',
//         PreReq: {
//           WrittenExamText1: 'Examen Escrito Requerido',
//           WrittenExamText2: 'Haga clic aquí para inscribirse',
//           ExamStatus: 'Estado',
//           ExamTestDate: 'Fecha de Prueba',
//           CertificationText1: 'Certificación Requerida',
//           CertificationText2: 'Haga clic aquí para inscribirse',
//           CertExpirationDate: 'Fecha de Expiración',
//           PreReqStatus: 'Estado:',
//           PreReqExpirationDate: 'Fecha de Expiración: ',
//           NotPurchased: 'No Comprado',
//           PreReqPara: 'No hay requisitos previos para',
//           PreReqPara2: 'credencial.',
//         },
//         BillingInfo: {
//           billinginfo: 'Información de Facturación',
//           billingNote: 'Nota: Aceptamos VISA, MasterCard y American Express.',
//           CardNumber: 'Número de Tarjeta de Crédito',
//           TypeCreditCard: 'Tarjeta de Crédito',
//           Error: 'Algo salió mal',
//           CloseWorkshopPara: 'No hay taller cerrado disponible contra este',
//           CloseWorkshopPara2: 'código de acceso.',
//           Process: 'Procesando',
//           Pay: 'Pagar $',
//           AccessCodefield: 'Ingrese el código de acceso',
//           AccessCode: 'Código de Acceso',
//           Loading: 'Cargando',
//           billingbuttonText: 'Haga clic aquí para ingresar la información de la tarjeta de crédito',
//           formbuttonText: 'Continuar',
//           formHeaderText: 'Pago',
//           Cardinfo: '¡Por favor, complete la información de la tarjeta de crédito!',
//           FirstNamefield: 'Nombre',
//           LastNamefield: 'Apellido',
//           Emailfield: 'Correo Electrónico',
//           Phonefield: 'Teléfono',
//           Countryfield: 'País',
//           Addressfield: 'Dirección',
//           Cityfield: 'Ciudad',
//           Statefield: 'Estado',
//           Zipfield: 'Código Postal',
//           SuccessResponse: 'Solicitud enviada con éxito.',
//         },
//         CredentialInfo: 'Información de Credencial',
//         CredentialEmpty: 'La información de la credencial está vacía',
//         Workshop: {
//           AvailableWorkshop: 'Talleres Disponibles',
//           WorkshopError: 'Debe seleccionar un taller para continuar.',
//           Startdate: 'Fecha/Hora de Inicio*',
//           Enddate: 'Fecha/Hora de Finalización*',
//           Location: 'Ubicación',
//           AvailableSeats: 'Asientos Disponibles',
//           ApplicationDeadline: 'Fecha Límite de Solicitud',
//           AuditEvent: 'Evento de Auditoría',
//           WorkshopPara1: 'Lo sentimos, no hay talleres disponibles actualmente. No puede continuar hasta que haya un taller disponible. Si desea ser agregado a la lista de espera, por favor envíe un correo electrónico a',
//           WorkshopPara2: 'Proporcione su nombre, ID de CCO e indique en qué taller le gustaría asistir. Le notificaremos cuando se añadan más talleres.',
//         },
//         Agreement: 'Acuerdo',
//         termsandCondition: 'Entiendo y acepto este Acuerdo. Además, entiendo y acepto que un pago para registrar o inscribirse en cualquier capacitación del personal de pruebas (como un curso o un taller) no se puede reembolsar una vez que mi solicitud y pago se envíen a CCO. Además, entiendo que dicho registro no se puede cancelar ni transferir. Al enviar un pago, acepto los',
//         FinancialTerms: 'Términos y Condiciones Financieros de CCO',
//         ProceedtCheckout: 'Proceder al pago',
//         Submit: 'Enviar',
//         Title: 'Por favor, espere mientras se procesa el pago. No actualice la página ni haga clic en el botón de retroceso.'
//       },
//       credentialjourney: {
//         CredentialJourney: "Viaje de la credencial",
//         testingPersonnel: "Personal de pruebas",
//         SelectJourneyButton: "Selecciona tu viaje de credencial"

//       },
//       CAD: {
//         CadManagement: {
//           pageHeading: "Personal de Pruebas",
//           ccoId: "ID de CCO",
//           PracLocations: "Ubicaciones Prácticas",
//           CustomCaD: "Mis CADs Personalizados",
//           BacktoPrac: "Volver a Sitio de Prueba Práctico",
//           AddnewCad: "Agregar nuevos CADs Personalizados",
//           PendingCad: "Mis CADs Personalizados Pendientes de Revisión",
//           ApproveCad: "Mis CADs Personalizados Aprobados",
//           CompleteCad: "Mis CADs Personalizados Completados",
//           ExpireCad: "Mis CADs Personalizados Caducados",
//           NoCustomCad: "No se encontraron CADs Personalizados",
//         },
//         BillingInformation: {
//           billinginfo: "Información de Facturación",
//           billingNote: "Nota: Aceptamos VISA, MasterCard y American Express.",
//           CardNumber: "Número de Tarjeta de Crédito",
//           TypeCreditCard: "Tarjeta de Crédito",
//           Error: "Algo salió mal",
//           Process: "Procesando",
//           Pay: "Pagar $",
//           billingbuttonText: "Haga clic aquí para completar la Información de la Tarjeta de Crédito",
//           formbuttonText: "Continuar",
//           formHeaderText: "Pago de CAD",
//           Cardinfo: "Es obligatorio completar la información de la tarjeta de crédito primero",
//           FirstNamefield: "Nombre",
//           LastNamefield: "Apellido",
//           Emailfield: "Correo Electrónico",
//           Phonefield: "Teléfono",
//           Countryfield: "País",
//           Addressfield: "Dirección",
//           Cityfield: "Ciudad",
//           Statefield: "Estado",
//           Zipfield: "Código Postal"
//         },
//         CADlanding: {
//           OptionYes: "Sí",
//           OptionNo: "No",
//           descrip1: "El peso de tu prueba es algo distinto a un diámetro exterior de 3 pies?",
//           for1: "Para todas las Grúas Móviles:",
//           descrip2: "Tiene tu grúa una extensión de estabilizador trasero de más de 24 pies?",
//           for2: "Para Camiones Grúa:",
//           descrip3: "Tu grúa tiene menos de 42 pies de pluma principal (jibs no permitidos)?",
//           descrip4: "Tu grúa solo opera con control remoto?",
//           descrip5: "Tu grúa tiene menos de 30 pies de pluma principal?",
//           for5: "Para Grúas de Cubierta Transportable:",
//           descrip6: "Tu grúa tiene menos de 50 pies de pluma principal?",
//           for6: "Para Grúas Telescópicas (excluyendo Cubierta Transportable):"
//         },
//         CustomCAD: {
//           pageHeading: "Personal de Pruebas",
//           OptionYes: "Sí",
//           OptionNo: "No",
//           TscPrac: "TSC-Práctico",
//           Next: 'Siguiente',
//           NewCustom: "Nuevo CAD Personalizado",
//           CustomCAD: "CAD Personalizado",
//           CADDescription: "Descripción del CAD",
//           CADQuestion: "Usa las preguntas a continuación para determinar si se requiere un CAD personalizado.",
//           CraneType: "Para proceder con el CAD Personalizado, selecciona el tipo de grúa:",
//           CADinfo: "Información",
//           Makefield: "Marca",
//           Modelfield: "Modelo",
//           SerialNumberfield: "Número de Serie",
//           MaxRelatedfield: "Capacidad Relacionada Máxima (Toneladas)",
//           LATfield: "¿Qué longitud de pluma puedes configurar entre 70-90 pies o 110-130 pies de pluma principal?",
//           otherfield: "¿Cuál es la longitud máxima de la pluma principal?",
//           Truckfield: "¿Es este un camión grúa?",
//           SelectOption: "Selecciona una opción",
//           OptionNull: "N/A",
//           Workingareafield: "Área de trabajo (grados)",
//           degree: "grados",
//           Cranefield: "Posición de la Grúa",
//           behindOption: "Detrás de la cabina",
//           CenterOption: "Montaje central",
//           RearOption: "Montaje trasero",
//           FifthOption: "Quinta rueda",
//           Truckfield: "Longitud de la caja del camión (pies)",
//           FrontOutriggerfield: "Extensión del estabilizador delantero (pies)",
//           BackOutriggerfield: "Extensión del estabilizador trasero (pies)",
//           RopeSizefield: "Tamaño y tipo de cuerda",
//           Allowablefield: "Tiro de línea permitido como se indica en la tabla de carga (lb)",
//           Diameterfield: "Diámetro del peso de prueba (pies)",
//           UploadLoadChart: "Subir la Documentación de la Tabla de Carga",
//           ClicktoUpload: "Haz clic para subir",
//           DownloadChart: "Descargar la Tabla de Carga",
//           UploadLinePullChart: "Subir la Tabla de Tiro de Línea",
//           DownloadLinePull: "Descargar Tiro de Línea",
//           UploadRangeDiagram: "Subir Diagrama de Alcance",
//           DownloadRange: "Descargar Diagrama de Alcance",
//           DeliveryType: "Selecciona el tipo de método de entrega que deseas comprar:",
//           StandardType: "- Entrega estándar de CAD Personalizado en 5-7 días hábiles.",
//           RushType: "- Entrega urgente en 2-3 días hábiles.",
//           SuccessError: "CAD Personalizado agregado con éxito.",
//           Title: "Espera mientras se procesa el pago. No actualices la página ni hagas clic en el botón de retroceso."
//         }
//       },
//       AvaliableCredential: {
//         pageHeading: "Personal de Pruebas",
//         AvailableCredentials: "Credenciales Disponibles",
//         NoCredentials: "No hay credenciales disponibles"
//       },
//       NewLocation: {
//         Attestation: {
//           entry: "Solo Sala de Pruebas, con un único punto de entrada/salida durante la prueba",
//           proctor: "Un puesto de trabajo de supervisor, ubicado en la sala de pruebas",
//           stations: "Una estación de prueba para el candidato",
//           parking: "Estacionamiento adecuado para la cantidad de vehículos anticipados",
//           restroom: "Se dispone de baño en el lugar",
//           equipment: "Espaciamiento del candidato/equipo de acuerdo con la política del CCO",
//           Attestation: "Certificación",
//           Completed:"Completo",

//           FillFields: "Por favor completa todos los campos",
//           InProgress: "En Progreso",

//           View: "Ver",
//           NothingSelected: "Nada seleccionado",
//           Edit: "Editar",
//           station: "Estaciones de prueba y equipo de acuerdo con la política del CCO",
//           proctors: "Estación de supervisor dedicada ubicada en la sala de pruebas",
//           entrys: "Una sala de pruebas dedicada y segura, con entrada/salida controlada durante la prueba",
//           Req: "¿Tienes los siguientes requisitos?"
//         },
//         newlocations: {
//           Nextstep: "Próximo paso",
//           Goback: "Regresar",
//           Previousstep: "Paso anterior",
//           SubmitApplication:"Enviar solicitud",
//           note:"Entiendo y acepto que una solicitud para registrar un sitio de prueba no puede ser cancelada ni transferida, y que los pagos de las tarifas de solicitud no pueden ser reembolsados una vez que la solicitud y el pago hayan sido enviados a CCO. También declaro que la información contenida en esta solicitud, así como la información en cualquier documentación complementaria requerida, es veraz y correcta. Entiendo que información insuficiente o inadecuada puede resultar en retrasos en el procesamiento. Al realizar un pago, acepto las políticas de CCO.",
//            FinancialTermsandConditions: "Términos y Condiciones Financieras",
//            AddEOTTestSite:"Agregar Sitio de Prueba EOT",

//            EOT: "Sitio de prueba EOT",
// Adding: "Ubicación nueva añadida con éxito",
// saei: "Guardar y salir",
// sa: "Guardar cambios"




//         },
//         Summary:{
//           Summary:"Resumen"
//         },
        
//         BillingInfo: {
//           Payment: "Pago",
//           FillFields: "Por favor completa todos los campos",
//           View: "Ver",
//           CardNumber: "Número de Tarjeta",
//           Completed:"Completo",

//           Amount: "Monto",
//           FirstName: "Nombre",
//           LastName: "Apellido",
//           Email: "Correo Electrónico",
//           Phone: "Teléfono",
//           Address: "Dirección",
//           Country: "País",
//           City: "Ciudad",
//           Zip: "Código Postal",
//           State: "Estado",
//           Edit: "Editar",
//           Cardinfo: "Es obligatorio completar la información de la tarjeta de crédito primero",
//           formHeaderText: "Pago de Ubicación",
//           billingbuttonText: "Haz clic aquí para completar la Información de la Tarjeta de Crédito",
//           formbuttonText: "Continuar",
//           billinginfo: "Información de Facturación",
//           billingNote: "Nota: Aceptamos VISA, MasterCard y American Express.",
//           Instruction: "Instrucciones: Completa la siguiente página utilizando la información y la dirección de facturación del titular de la tarjeta de crédito."
//         },
//         TestingFacilityincludingSignage: {
//     Photo: "2. Foto de la Instalación de Pruebas, incluyendo Señalización",
//     upload: "Subir Foto de la Instalación de Pruebas",
//     picdetail: "Máx. 5MB – solo se admiten tipos de archivos .jpeg/.jpg, .png o .pdf",
//     uploadhere: "Haz clic aquí para subir",
//     sampletesting: "Foto de Ejemplo de la Instalación de Pruebas",
// },

//         TestingSite: {
//           TestSiteCapacity: "Capacidad del Sitio de Pruebas",
//           FillFields: "Por favor completa todos los campos",
//           View: "Ver",
//           Edit: "Editar",
//           InProgress: "En Progreso",
//           NoAnswer: "No respondido",
//           Requir: "¿Tienes la velocidad de descarga/carga mínima requerida?",
//           Testingstations: "Estaciones de prueba",
//           Completed:"Completo",
//           NotCompleted: "No completado",
//           hA: "Requisitos de Internet del Sitio de Pruebas EOT (Mínimos)",
//           para1A: "Las instalaciones de prueba deben cumplir con los siguientes requisitos técnicos para ser aprobadas para la prueba EOT:",
//           list1: "Fuente de Internet no celular (por ejemplo, banda ancha, cable)",
//           list2: "Velocidad de descarga recomendada de 12 Mbps por dispositivo (mínimo 2 Mbps)",
//           list3: "Velocidad de carga recomendada de 3 Mbps por dispositivo (mínimo 2 Mbps)",
//           Note1A: "Nota: No se admiten dispositivos de punto de acceso móvil o MiFi celular.",
//           list4: "Red de internet dedicada",
//           list5: "Recomendado: red de internet por cable (Ethernet)",
//           list6: "Mínimo: Red de Internet inalámbrica (WiFi)",
//           list7: "Privada y protegida con contraseña",
//           list8: "Se recomiendan routers con las características de calidad de servicio activadas (para priorizar el tráfico de EOT)",
//           Note2A: 'Nota: No se admiten redes públicas o compartidas (por ejemplo, WiFi público de hotel).',
//           para2A: 'Revise los requisitos técnicos completos que se encuentran en el Manual de Administración de Pruebas - Pruebas EOT, y acceda a herramientas en línea para medir la velocidad de internet – ',
//           click: 'haga clic aquí',
//           para3A: '¿No conoce la velocidad de su internet? Contacte al soporte técnico del centro de pruebas o realice una prueba de velocidad. Este enlace le permitirá realizar una prueba de velocidad en vivo:',
//           link: "https://www.speedtest.net/",
//           performthetest: "Asegúrate de realizar la prueba en la sala de pruebas que planeas usar",
//           capacity: "B. Capacidad del Sitio de Pruebas",
//           factors: "Basado en todos los factores, incluida la velocidad de internet disponible, la red de internet y cualquier limitación de espacio o equipo, ¿cuántas estaciones de prueba de candidatos puede soportar la instalación?",
//           attestation3: "C. Declaración de Atestación",
//           conformation: "He confirmado que esta instalación de pruebas cumple con los Requisitos de Internet para el Sitio de Pruebas EOT, y que las velocidades de descarga/subida de internet soportarán el número de estaciones de prueba de candidatos ingresadas anteriormente.",
//           option: "Selecciona una opción",
//           Yes: "Sí",
//           No: "No - Se requiere Sí para enviar la solicitud"
          
//         },
//         TestingRoomLayout: {
//           roomlayout: "4. Diseño de la Sala de Pruebas",
//           upload: "Subir Diseño de la Sala de Pruebas",
//           picdetail: "Máx. 10MB – solo se admiten tipos de archivos .jpeg/.jpg, .png o .pdf",
//           uploadhere: "Haz clic aquí para subir",
//           download: "Descargar Formulario de Diseño de la Sala de Pruebas"
//       },
      
//         Representation: {
//             representativeInformation: "Información del Representante del Sitio de Prueba",
//             testingFacility: "El representante del sitio de prueba es el representante de la empresa de la instalación de prueba. Esta persona autoriza al coordinador de sitio de prueba a configurar este sitio de prueba.",
//             name: "Nombre Completo",
//             phone: "Teléfono Móvil",
//             email: "Correo Electrónico",
//             emailconfirmation: "Confirmación de Correo Electrónico",
//             uploadtsragreement: "Subir Acuerdo TSR",
//             upload: "Haga clic para subir",

//             maxsizemb: "10MB máximo (pdf)",
//             terms_conditions: "El campo de términos y condiciones es obligatorio",
//             downloadagreement: "Descargar el Acuerdo del Representante del Sitio de Prueba" 
//         },
//           TestSite: {
//             testSite: "1. Información del Sitio de Prueba",
//             FacilityTest: "Nombre de la Empresa de Instalación de Prueba",
//             requireName: "El campo del nombre de la empresa es obligatorio",
//             Street1: "Calle 1",
//             steet1Require: "El campo de calle 1 es obligatorio",
//             street2: "Calle 2",
//             eg: "(p. ej., Suite #, Edificio #, Habitación #)",
//             country: "País",
//             nocountry: "No se encontraron países. Por favor, inténtelo de nuevo más tarde.",
//             countryRequire: "El campo de país es obligatorio",
//             city: "Ciudad",
//             cityField: "El campo de ciudad es obligatorio",
//             state: "Estado",
//             nostates: "No se encontraron estados. Por favor, inténtelo de nuevo más tarde.",
//             staterequied: "El campo de estado es obligatorio",
//             zip: "Código Postal",
//             zipRequired: "El campo de código postal es obligatorio",
//             securefacility: "¿Es esta una instalación segura que tiene requisitos de acceso adicionales?",
//             securerequire: "El campo de instalación segura es obligatorio",
//             option: "Seleccione una opción",
//             YES: "Sí",
//             NO: "No",
//             Timezone: "Zona Horaria",
//             timezonerequired: "El campo de zona horaria es obligatorio",
//             notimezone: "No se encontró ninguna zona horaria. Por favor, inténtelo de nuevo más tarde.",
//             Additional: "Por favor, proporcione instrucciones de seguridad adicionales que sean necesarias para que el personal de CCO acceda a la instalación.",
//               africa: "Hora Estándar de África Oriental",
//               hawain: "Hora Estándar de Hawái",
//               alaskan: "Hora Estándar de Alaska",
//               pacific: "Hora Estándar del Pacífico",
//               pacificmexico: "Hora Estándar del Pacífico (México)",
//               us: "Hora Estándar de las Montañas (AZ)",
//               mountain: "Hora Estándar de las Montañas",
//               central: "Hora Estándar Central",
//               eastren: "Hora Estándar del Este",
//               atlantic: "Hora Estándar del Atlántico",
//               west: "Hora Estándar del Pacífico Occidental",
//               usAz: "Hora Estándar de las Montañas (AZ)",


//               selecttz: "Cargando... : 'Selecciona una zona horaria'",
//               selectstate: "Cargando... : 'Selecciona un estado'",
//               selectcountry: "Cargando... : 'Selecciona un país'",
              
//         },
//         STestSiteRepresentativeInformation:{                
//         testsite:"Información del Representante del Sitio de Prueba",
//         InProgress:"En Progreso",
//         FullName:"Nombre Completo",
//         fill: "Por favor complete todos los campos",
//         View:"Ver",
//         Phone:"Teléfono",
//         Completed:"Completo",
//         Email:"Correo Electrónico",
//         TSRAgreement:"Acuerdo de TSR",
//         Edit:"Editar",
//         Notcompleted:"No completo",
        
//         },
//         LocationDoorSign:{
//           LocationDoorSign:"Cartel de Ubicación",
//           InProgress:"En Progreso",
//           Completed:"Completo",
//           fill: "Por favor complete todos los campos",
//           View:"Ver",
//           Edit:"Editar"
//         },
//         LocationSitePlan:{
//           LocationSitePlan:"Plano de Ubicación",
//           fill: "Por favor complete todos los campos",
//           InProgress:"En Progreso",
//           Completed:"Completo",
//           View:"Ver",
//           Edit:"Editar"
//         },
//         LocationInformation:{
//           LocationInformation: "Información de Ubicación",
//           InProgress:"En Progreso",
//           View:"Ver",
//           fill: "Por favor complete todos los campos",
//           CompanyName:"Nombre de la Empresa",
//           Street1:"Calle 1",
//           Street2:"Calle 2",
//           City:"Ciudad",
//           Country:"País",
//           Additionalsecurity:"Seguridad Adicional",
//           Zip:"Código Postal",
//           State:"Estado",
//           Securefacility:"¿Instalación Segura?",
//           Edit:"Editar",
//           Completed:"Completo",
//           Notcompleted:"No completo",
//           waiting:"Esperando..."

//         }

        
        
//       },
//       CredentialRenewal: {
//         testingPersonel: 'Personal de pruebas',
//         JourneyStatus: '  Estado del viaje',
//         WrittenExamText1: 'Se requiere examen escrito',
//         WrittenExamText2: 'Haz clic aquí para inscribirte',
//         ExamStatus: 'Estado',
//         ExamTestDate: 'Fecha de la prueba',
//         CertificationText1: 'Se requiere certificación',
//         CertificationText2: 'Haz clic aquí para inscribirte',
//         CertExpirationDate: 'Fecha de vencimiento',
//         PreReqStatus: 'Estado: ',
//         PreReqExpirationDate: 'Fecha de vencimiento:',
//         NotPurchased: ' No comprado',
//         PreReqPara: 'No hay pre-requisitos para',
//         PreReqPara2: 'la credencial.',
//         billinginfo: 'Información de Facturación',
//         billingNote: 'Nota: Aceptamos VISA, MasterCard y American Express.',
//         CardNumber: 'Número de Tarjeta de Crédito',
//         billingbuttonText: 'Haz clic aquí para ingresar la Información de la Tarjeta de Crédito',
//         formbuttonText: 'Continuar',
//         formHeaderText: 'Pago',
//         Cardinfo: '¡Por favor, completa la información de la tarjeta de crédito!',
//         FirstNamefield: 'Nombre',
//         LastNamefield: 'Apellido',
//         Emailfield: 'Correo Electrónico',
//         Phonefield: 'Teléfono',
//         Countryfield: 'País',
//         Addressfield: 'Dirección',
//         Cityfield: 'Ciudad',
//         Statefield: 'Estado',
//         Zipfield: 'Código Postal',
//         SuccessResponse: 'Solicitud enviada con éxito.',
//         CredentialInfo: 'Información de la Credencial',
//         CredentialEmpty: 'La información de la credencial está vacía',
//         AvailableWorkshop: 'Talleres Disponibles',
//         WorkshopError: 'Debes seleccionar un taller para continuar.',
//         Startdate: 'Fecha/Hora de Inicio*',
//         Enddate: 'Fecha/Hora de Fin*',
//         Location: 'Ubicación',
//         AvailableSeats: 'Asientos Disponibles',
//         ApplicationDeadline: 'Fecha Límite de Solicitud',
//         AuditEvent: 'Evento de Auditoría',
//         WorkshopPara1: 'Lo sentimos, actualmente no hay talleres disponibles. No puedes continuar hasta que haya un taller disponible. Si deseas ser agregado a la lista de espera, envía un correo electrónico a',
//         WorkshopPara2: 'Proporciona tu nombre, ID de CCO, e indica en qué taller te gustaría participar. Te notificaremos cuando se agreguen más talleres.',
//         Agreement: 'Acuerdo',
//         termsandCondition: 'Entiendo y acepto este Acuerdo. También entiendo y acepto que un pago para registrarse o inscribirse en cualquier capacitación de personal de pruebas (como un curso o taller) no puede ser reembolsado una vez que mi solicitud y pago hayan sido enviados a CCO. Además, entiendo que dicha inscripción no se puede cancelar ni transferir. Al enviar un pago, acepto los',
//         FinancialTerms: 'Términos y Condiciones Financieros de CCO',
//         ProceedtCheckout: 'Proceder al pago',
//         Submit: 'Enviar',
//         Title: 'Por favor, espera mientras se procesa el pago. No actualices la página ni hagas clic en el botón de retroceso.',
//         TypeCreditCard: 'Tarjeta de Crédito',
//         Error: 'Algo salió mal',
//         CloseWorkshopPara: 'No hay talleres cerrados disponibles para este',
//         CloseWorkshopPara2: 'código de acceso.',
//         Process: 'Procesando',
//         Pay: 'Pagar $',
//         AccessCodefield: 'Ingresa el código de acceso',
//         AccessCode: 'Código de Acceso',
//         Loading: 'Cargando',
//         Renew: 'Renovar',
//         AuthorizedError: 'Nuestros registros indican que no tienes autorización para acceder a esta página. Por favor, contacta a CCO si tienes alguna pregunta.',
//         NotRenewed: 'No Renovado',
//         NotAvailable: 'No Disponible',
//         ProductError: '¡No se encontró la tarifa del producto de credencial!'
//       },
//        GeneratePdf: {
//         Sheet  :{ 
//           sheet1:"Generar PDFs de Hojas de Puntaje",
//           sheet2: "Generar PDF de Examen de Señalizador",
//           sheet3: "Generar PDF de CRG de Señalizador"
//         },
//         Headerpage1:{
//         practicalExamScoreSheetDownload: "Descarga de Hoja de Puntuación del Examen Práctico",
//         termsAndConditions: "Al descargar las hojas de puntuación, aceptas los siguientes términos y condiciones:",
//         credentialProctor: "Al descargar las hojas de puntuación, aceptas los siguientes términos y condiciones:",
//         scoreSheetRestrictions: "Solo puedes descargar hojas de puntuación para las designaciones de credenciales que posees.",
//         approved: "Estás aprobado para administrar los exámenes prácticos de CCO.",
//         scoreSheetUsage: "Cada hoja de puntuación está serializada y solo se puede usar una vez. Puedes imprimirlas una vez, y no se pueden hacer copias después de la impresión.",
//         expirationDetails: "Cada hoja de puntuación tiene una fecha de vencimiento (esquina superior derecha) y no puede usarse después de su fecha de vencimiento.",
//         destructionPolicy: "Las hojas de puntuación no utilizadas (caducadas) deben ser destruidas, y los archivos PDF deben ser eliminados después de su uso.",
//         copyrightProtection: "Entiendes que las hojas de puntuación están protegidas por la ley federal de derechos de autor.",
//         sharingRestriction: "No compartirás este enlace ni ninguna hoja de puntuación con nadie. Solo tú estás autorizado para manejar o enviar las hojas de puntuación de los candidatos a los exámenes prácticos.",
//         secureStorageRequirement: "Guardarás todas las hojas de puntuación en un lugar seguro.",
//         deletionRequirement: "Eliminarás todas las descargas o destruirás cualquier copia impresa de las hojas de puntuación si CCO así lo requiere.",
//         consequenceAwareness: "Entiendes las consecuencias tal como se describen en el Manual del Supervisor de Exámenes Prácticos sobre la custodia, cuidado y control de las hojas de puntuación.",
//         sanctionAvoidance: "No estás actuando para eludir la sanción de otro Supervisor de Exámenes Prácticos.",
//         examAdministration: "Aceptas administrar los exámenes de acuerdo con las políticas y procedimientos de CCO.",
//         printingRecommendation:"Se recomienda encarecidamente que todas las hojas de puntuación se impriman a doble cara.",
//         acknowledgment: "Reconozco que he leído y entendido las políticas y procedimientos anteriores en su totalidad y acepto cumplirlos. (Esta casilla debe estar marcada para realizar un pedido.)",
//         label: "Tipo de Hoja de Puntuación *",
//         dropdownPlaceholder1: "Selecciona una opción",
//         label2: "Cantidad *",
//         dropdownPlaceholder: "Selecciona una opción",
//         dropdown1: "EQUIPO DE PERFORACIÓN PARA ANCLAJES/MICROPILAS" ,
//           dropdown2: "Grúa articulada",
//           dropdown3: "Retroexcavadora articulada",
//           dropdown4: "OPERADOR DE BOMBA DE CONCRETO – CLASE 1",
//           dropdown5:"OPERADOR DE BOMBA DE CONCRETO – CLASE 2",
//           dropdown6: "MARRO DE PILAS DEDICADO",
//           dropdown7: "GRÚA DE EXCAVACIÓN",
//           dropdown8: "EQUIPO DE PERFORACIÓN PARA FUNDACIONES",
//           dropdown9: "GRÚA DE BRAZO DE MALLA MCO-LATTICE BOOM CRANE (LAT)",
//           dropdown10: "GRÚA MCO-TELESCOPIC BOOM-SWING CAB (TLL)",
//           dropdown11: "GRÚA MCO-TELESCOPIC BOOM-FIXED CAB (TSS)",
//           dropdown12: "OPERADOR DE GRÚA PUENTE",
//           dropdown13: "RIGGER NIVEL I",
//           dropdown14: "RIGGER NIVEL II",
//           dropdown15: "OPERADOR DE GRÚA DE CAMIÓN DE SERVICIO",
//           dropdown16: "SEÑALIZADOR",
//           dropdown17: "OPERADOR DE MANIPULADOR TELESCÓPICO - FIJO",
//           dropdown18: "OPERADOR DE MANIPULADOR TELESCÓPICO - GIRATORIO",
//           dropdown19: "OPERADOR DE GRÚA TORRE", 
//           back: "volver"              

//         },
//         Headerpage2: {
//           signalPerson: "DESCARGA DEL EXAMEN DE SEÑALIZACIÓN Y GUÍA DE RESPUESTAS",
//           agreement: "Al descargar el Examen Práctico de Señalización y la Guía de Respuestas del Candidato, usted acepta los siguientes términos y condiciones:",
//           credentialPracticalExam: "Usted es un proctor de examen práctico acreditado en el programa de señalización.",
//           approvalToAdminister: "Usted está aprobado para administrar exámenes prácticos de señalización.",
//           responseGuide: "Usted entiende que el Examen y la Guía de Respuestas del Candidato están protegidos por la ley federal de derechos de autor.",
//           shareLink:"No compartirá este enlace, el Examen o la Guía de Respuestas del Candidato con nadie.",
//           CandidateResponse: "Usted mantendrá el Examen y la Guía de Respuestas del Candidato en un lugar seguro.",
//           copiesOfExam: "Usted eliminará todas las copias del Examen y la Guía de Respuestas del Candidato si CCO así lo requiere.",
//           consequenses: "Usted entiende las consecuencias según se describe en el Manual del Proctor de Exámenes Prácticos sobre la custodia, cuidado y control del Examen y la Guía de Respuestas del Candidato.",
//           practicalExamProtor: "No está actuando para eludir la sanción de otro Proctor de Exámenes Prácticos.",
//           CCOPoliceandProcedure: "Usted acepta administrar los exámenes de acuerdo con las políticas y procedimientos de CCO.",
//           acknowledgment: "Reconozco que he leído y comprendido las políticas y procedimientos anteriores en su totalidad y acepto cumplir con ellos.",
        
//       } ,
      
//     },
//     EOTComponents:{
//       EOTComponent:{
//           TestingPersonnel:"Personal de Pruebas",
//           TestSiteLocations:"Ubicaciones de Sitios de Pruebas",
//           CCOID:"ID de CCO",
//           MyEOTTestSites:"Mis Sitios de Pruebas EOT",
//           AddnewEOTtestsite:"Agregar nuevo sitio de prueba EOT",
//           Notestsitesfound:"No se encontraron sitios de prueba",
//           MyPendingPaymentEOTTestSites:"Mis Sitios de Pruebas EOT en Pago Pendiente",
//           MyPendingReviewEOTTestSites:"Mis Sitios de Pruebas EOT en Revisión Pendiente",
//           Notestsitesfound: "No se encontraron sitios de prueba",
//           MyApprovedEOTTestSites:"Mis Sitios de Pruebas EOT Aprobados",
//           Notestsitesfound:"No se encontraron sitios de prueba",
//           MyExpiredEOTTestSites:"Mis Sitios de Pruebas EOT Expirados",
//           Notestsitesfound: "No se encontraron sitios de prueba",
//           manageMyTestAdministrations:"Gestionar Mis Administraciones de Pruebas"

//       },
//       Mainpage:{
//         ExpireDate:"Fecha de Expiración:",
//         View:"Ver",
//         CompanyName:"Nombre de la Empresa",
//         Address:"Dirección",
//         Status:"Estado",
//         ExpireDate:"Fecha de Expiración",
//         Edit:"Editar",
//         RenewNow:"Renovar Ahora"
//     }
    
//   },

// Banner:{
// CandidateAccessControl:{
//   note:"IMPORTANTE: ¡Necesitamos tu foto! Por favor, sube una foto de tu rostro lo antes posible para asegurar que no haya retrasos en las pruebas o en la recepción de tu tarjeta de certificación.",
//   red: 'Su cuenta está bloqueada. Si tiene alguna pregunta, comuníquese con el Servicio al Cliente en candidate@nccco.org o al 703-560-2391.',
// orange: 'Su cuenta está actualmente suspendida. Algunos datos de la cuenta y relacionados aún pueden ser visibles. Si tiene alguna pregunta, comuníquese con el Servicio al Cliente en candidate@nccco.org o al 703-560-2391.',
// yellow: 'Actualmente se requiere que programe la fecha y hora de su cita para el examen con al menos dos días hábiles de anticipación. Si tiene alguna pregunta, comuníquese con el Servicio al Cliente en candidate@nccco.org o al 703-560-2391.'

// },
// testingpersonalBanner: {
//   yellow: 'Su cuenta de personal de pruebas está sujeta al requisito de programación avanzada de administración de pruebas. Solo puede crear o ser asignado a una administración de pruebas con una fecha/hora de inicio al menos dos días hábiles en el futuro. Si tiene alguna pregunta, comuníquese con el Soporte de Personal de Pruebas en testingpersonnel@nccco.org o al 703-560-2391 ext. 812.',
//   orange: 'Su cuenta de personal de pruebas está actualmente suspendida. Algunos datos relacionados con la cuenta aún pueden ser visibles. Si tiene alguna pregunta, comuníquese con el Soporte de Personal de Pruebas en testingpersonnel@nccco.org o al 703-560-2391 ext. 812.',
//   red: 'Su cuenta de personal de pruebas está bloqueada. Si tiene alguna pregunta, comuníquese con el Soporte de Personal de Pruebas en testingpersonnel@nccco.org o al 703-560-2391 ext. 812.',
// }

// },
// cards: {
//   ContactCredentialCard: {
//     Status: "Estado",
//     TrainingDue: "Entrenamiento Pendiente"
//   }
// },
// NewPracLocation: {
//   LocationInfromation: {
//     TestingFacilityCompanyName: "Nombre de la empresa de la instalación de pruebas",
//     Street1: "Calle 1",
//     TestSiteInformation: "Información del sitio de prueba",
//     Street2: "Calle 2",
//     str2: "(por ejemplo, Suite #, Edificio #, Habitación #)",
//     Selectacountry: "Seleccionar un país",
//     Country: "País",
//     noCountry: "No se encontraron países. Por favor, intente nuevamente más tarde.",
//     City: "Ciudad",
//     State: "Estado",
//     Selectastate: "Seleccionar un estado",
//     noState: "No se encontraron estados. Por favor, intente nuevamente más tarde.",
//     Zip: "Código postal",
//     zipreq: "¿Es esta una instalación segura, que tiene requisitos de acceso adicionales?",
//     Selectanoption: "Seleccionar una opción",
//     Yes: "Sí",
//     No: "No",
//     Timezone: "Zona horaria",
//     Selectatimezone: "Seleccionar una zona horaria",
//     Notimezone: "No se encontró zona horaria. Por favor, intente nuevamente más tarde.",
//     provide: "Por favor proporcione instrucciones de seguridad adicionales que serían necesarias para que el personal de CCO acceda a la instalación"
//   },
//   newpracloc: {
//     PracticalTestSite: "Sitio de Pruebas Prácticas",
//     AddPracticalTestSite: "Agregar Sitio de Pruebas Prácticas",
//     note: "Entiendo y acepto que una solicitud para registrar un sitio de prueba no puede ser cancelada ni transferida, y los pagos de las tarifas de solicitud no pueden ser reembolsados una vez que la solicitud y el pago se envíen a CCO. También declaro que la información contenida en esta solicitud, así como la información en cualquier documentación requerida que la acompañe, es verdadera y correcta. Entiendo que la información insuficiente o inadecuada puede resultar en demoras en el procesamiento. Al enviar un pago, acepto los términos de CCO.",
// note2: "Términos y condiciones financieros",
// added: "Ubicación nueva añadida con éxito",
// back: "Regresar",
// step: "Paso anterior",
// Submit: "Enviar solicitud",
// next: "Siguiente paso",
// sa: "Guardar cambios",
// saei: "Guardar y salir",
// wait: "Actualizando, por favor espere...",
// plz: "Espere mientras se procesa la presentación. No haga clic en Atrás ni Actualizar."


//   },
//   locationDoorSign: {
//     photo: "Foto de la instalación de pruebas, incluida la señalización",
//     upphoto: "Subir foto de la instalación de pruebas",
//     mb: "Máximo 5MB",
//     upload: "Haz clic aquí para subir",
//     sample: "Foto de ejemplo de la instalación de pruebas",

//     CourseArea: "Foto del área del curso de pruebas prácticas",
// uploadphoto: "Subir foto del área de pruebas prácticas",
// mb: "Máximo 10MB",
// upload: "Haz clic aquí para subir"

//   },
//   Examofferd: {
//     PracticalExams: "Exámenes Prácticos Ofrecidos",
//     select: "Por favor, seleccione los exámenes prácticos de CCO que planea ofrecer en este sitio de pruebas.",
//     note: "Entiendo y reconozco que CCO ha establecido criterios únicos y específicos para cada tipo de examen práctico y el equipo relacionado que se utilizará para las pruebas, que pueden incluir, entre otros, el tamaño del área de prueba, la superficie del área de prueba, el nivel del suelo, la proximidad a obstrucciones y peligros, y que el equipo que se utilizará para las pruebas cumple con todas las normas aplicables de la industria y del fabricante. Atestiguo que he leído y me he familiarizado con los criterios de CCO para los Sitios de Pruebas Prácticas, como se establece en el Manual de Administración de Pruebas - Prácticas, y acepto preparar este Sitio de Prueba y el equipo relacionado de acuerdo con los requisitos establecidos. Además, atestiguo que el curso de pruebas está libre de todos los materiales de ayuda para el entrenamiento y materiales de referencia/entrenamiento."
//   },
//   TestSiteRepresentativeInfo: {
//     TestSiteRepresentativeInformation: "Información del Representante del Sitio de Pruebas",
//     note: "El Representante del Sitio de Pruebas es el Representante de la Empresa de las Instalaciones de Pruebas. Esta persona autoriza al Coordinador del Sitio de Pruebas a configurar este sitio de pruebas.",
//     FullName: "Nombre Completo",
//     MobilePhone: "Teléfono Móvil",
//     Email: "Correo Electrónico",
//     EmailConfirmation: "Confirmación de Correo Electrónico",
//     UploadTSR: "Subir Acuerdo de Representante del Sitio de Pruebas",
//     mb: "Máximo 10MB (pdf)",
//     btn: "Haga clic para Subir",
//     download: "Descargar Acuerdo del Representante del Sitio de Pruebas"
//   },
//   Attestation: {
//     Attestation: "Certificación",
//     req: "¿Cuenta con los siguientes requisitos?",
//     ade: "Estacionamiento adecuado para la cantidad de vehículos anticipados",
//     availible: "Baños disponibles en el sitio",
//     pretest: "Un área de reunión previa a la prueba para los candidatos que esperan para realizar la prueba (sin posibilidad de observar a los candidatos que están en una prueba en vivo)"
//   },
//   BillingInformation: {
//     BillingInformation: "Información de Facturación",
//     note: "Nota: Aceptamos VISA, MasterCard y American Express.",
//     note2: "Es obligatorio completar primero la información de la tarjeta de crédito.",
//     FirstName: "Nombre",
//     LastName: "Apellido",
//     Email: "Correo Electrónico",
//     Phone: "Teléfono",
//     Country: "País",
//     Address: "Dirección",
//     City: "Ciudad",
//     State: "Estado",
//     Zip: "Código Postal",
//     btn1: "Haga clic aquí para completar la información de la tarjeta de crédito",
// Continue: "Continuar",
// LocationPayment: "Pago de Ubicación"

//   }
  
  
  
  
  
  
// },
// SummaryPracSite: {
//   LocationInformation: {
//     LocationInformation: "Información de la Ubicación",
//     com: "Completado",
//     inprog: "En Progreso",
//     note: "Por favor, complete todos los campos",
//     View: "Ver",
//     CompanyName: "Nombre de la Empresa",
//     Street1: "Calle 1",
//     Street2: "Calle 2",
//     City: "Ciudad",
//     Zip: "Código Postal",
//     State: "Estado",
//     Country: "País",
//     fac: "¿Instalación segura?",
//     sec: "Seguridad adicional",
//     Notcompleted: "No completado",
//     waiting: "Esperando...",
//     Yes: "Sí",
//     No: "No",
//     Edit: "Editar"

//   },
//   LocationDoorSign: {
//     LocationDoorSign: "Letrero de la Puerta de la Ubicación",
//     com: "Completado",
//     inprog: "En Progreso",
//     note: "Por favor, complete todos los campos.",
//     sup: "Tipo de archivo no compatible",
//     View: "Ver",
//     Edit: "Editar"
//   },
//   PracticalTesting: {
//     PracticalTesting: "Foto del Área del Curso de Pruebas Prácticas",
//     com: "Completado",
//     inprog: "En Progreso",
//     note: "Por favor, complete todos los campos.",
//     pracArea: "Foto del Área de Prueba Práctica",
//     Edit: "Editar"
//   },
//   PracticalExamOffered: {
//     PracticalExamOffered: "Examen Práctico Ofrecido",
//     com: "Completado",
//     inprog: "En Progreso",
//     View: "Ver",
//     note: "Por favor, complete todos los campos.",
//     Nothingselected: "Nada seleccionado",
//     Attes: "¿Certificación Práctica?",
//     Edit: "Editar"
//   },
//   TestSite: {
//     TestSite: "Información del Representante del Sitio de Pruebas",
//     com: "Completado",
//     inprog: "En Progreso",
//     View: "Ver",
//     note: "Por favor, complete todos los campos.",
//     FullName: "Nombre Completo",
//     Phone: "Teléfono",
//     Email: "Correo Electrónico",
//     TSR: "Acuerdo TSR",
//     Edit: "Editar",
//     notcom: "No completado"
//   },
//   Attestation: {
//     Attestation: "Atestación",
//     com: "Completado",
//     inprog: "En progreso",
//     View: "Ver",
//     note: "Por favor, complete todos los campos",
//     no: "Nada seleccionado",
//     Edit: "Editar"
//   },
//   Payment: {
//     Payment: "Pago",
//     com: "Completado",
//     inprog: "En progreso",
//     View: "Ver",
//     note: "Por favor, complete todos los campos",
//     CardNumber: "Número de tarjeta",
//     Amount: "Cantidad",
//     FirstName: "Nombre",
//     LastName: "Apellido",
//     Email: "Correo electrónico",
//     Phone: "Teléfono",
//     Address: "Dirección",
//     Country: "País",
//     City: "Ciudad",
//     State: "Estado",
//     Edit: "Editar"
//   },
//   Summary: {
//     Summary: "Resumen"
//   }
// },


login: {
  login: {
    login: "¡Bienvenido al Portal myCCO!",
          reg: "Inicia sesión o regístrate en una cuenta del portal myCCO para:",
          submit: "Enviar una solicitud de examen",
          update: "Actualizar tu información de contacto",
          exam: "Ver los resultados de los exámenes y certificaciones actuales",
          create: "Crea una cuenta en unos pocos pasos sencillos. Mira un video paso a paso sobre cómo registrarte como",
          candidate: "candidato existente",
          msg: "(alguien que ya ha realizado pruebas con CCO en el pasado o está certificado) o como",
          user: "nuevo usuario"
  }
},
PhotoManagement: {
  PhotoIDUpload: {
    PhotoManagement: "Envía tu Foto",
    Submission: "Envía tu foto de identificación emitida por el gobierno",
    Eg: "(por ejemplo, Licencia de Conducir, Pasaporte)",
    Note: "NOTA: Tu identificación será usada para validar tu identidad y será",
    permanentlydeleted: "eliminada permanentemente",
    Verified: "una vez verificada.",
    UpdatePhoto: "Actualizar Foto",
    Upload: "Hacer clic para Subir",
    Webcam: "Usar Cámara",
    Rotate: "Rotar",
    ZoomIn: "Acercar",
    ZoomOut: "Alejar",
    Remove: "Eliminar",
    Reset: "Restablecer",
    Image: "Seleccionar Imagen",
    ID: "Asegúrate de que tu foto cumpla con las pautas de CCO",
    Capture: "Capturar",
    Close: "Cerrar"
  },
  PhotoManagement: {
    PhotoManagement: "Envía tu Foto",
    Dashboard: "Tablero",
    Request: "La solicitud ya ha sido enviada. Por favor contacta con CCO si tienes alguna pregunta.",
    Record: "Nuestros registros indican que no estás autorizado para acceder a esta página. Por favor contacta con CCO si tienes alguna pregunta.",
    Submission: "Envía tu foto y tu identificación emitida por el gobierno",
    Success: "Tu cuenta se actualizó con éxito, haz clic en el botón Volver al Tablero para continuar.",
    Return: "Volver al Tablero",
    error: "Por favor, sube solo archivos de imagen con los siguientes formatos: JPEG, JPG, PNG.",
    Submitting: "Enviando...",
    Submit: "Enviar Foto",
    Waiting: "Por favor espera mientras se procesa tu envío. No actualices la página ni hagas clic en el botón atrás."
  },
  PhotoUpload: {
   Submission: "Envía tu foto estilo pasaporte",
   Eg: "(foto a color, fondo sólido, sin sombrero, sin gafas de sol, sin filtros)",
  Note: "NOTA: Esta foto será usada para tu verificación de certificación (VCO) una vez que la obtengas.",
   Update: "Actualizar Foto",
  Upload: "Hacer clic para Subir",
  Webcam: "Usar Cámara",
  Rotate: "Rotar",
  ZoomIn: "Acercar",
  ZoomOut: "Alejar",
  Remove: "Eliminar",
  Image: "Seleccionar Imagen",
  Reset: "Restablecer",
  Submit: "Haz clic aquí para las pautas de envío de fotos",
  Capture: "Capturar",
  Close: "Cerrar",
  PhotoRec: "Cara no detectada. Por favor proporciona una imagen con un rostro visible."
  }
  
  
},
  Footer:{
   Footer: "Copyright 2022-2025. Todos los derechos reservados."
  },

  CardOrder: {
    headerDashboard: "Tablero",
    headerCardOrder: "Pedido de Tarjeta de Billetera",
    subheading: "Formulario de Pedido de Tarjeta de Billetera CCO",
    notEligible: "Nuestros registros indican que no está autorizado para acceder a esta página. Por favor, contacte a CCO si tiene alguna pregunta.",
    walletCostNote: "El costo de una tarjeta de billetera es de $30. Los pedidos se procesan al recibirlos, por favor permita hasta tres semanas para la entrega.",
    shippingInformation: {
        title: "Información de Envío",
        shippingCheck: "Usar mi dirección postal registrada.",
        Attention: "Atención",
        Street1: "Calle 1",
        Street2: "Calle 2",
        city: "Ciudad",
        zip: "Código Postal",
        note: "Nota: Si necesita actualizar su dirección postal, vaya a ",
        noteLink: "detalles de la cuenta",
        nextButton: "Siguiente"
    },
    billingInformation: {
        title: "Información de Facturación",
        note: "Nota: Aceptamos VISA, MasterCard y American Express.",
        cardNumber: "Número de Tarjeta de Crédito",
        hostedFormText:"Haga clic aquí para completar la información de la tarjeta de crédito",
        formButtonText:"Continuar",
        formHeaderText:"Pago de Pedido de Tarjeta de Billetera",
        CreditInfo:"Por favor, complete la información de la tarjeta de crédito.",
        FirstName: "Nombre",
        LastName: "Apellido",
        Email: "Correo Electrónico",
        Phone: "Teléfono",
        Country: "País",
        Address: "Dirección",
        City: "Ciudad",
        State: "Estado",
        Zip: "Código Postal",
        requestSuccess:"Solicitud enviada con éxito.",
        payButton: "Pagar"
    },
    loadingMessage: "Espere mientras se procesa su pedido. No actualice la página ni haga clic en el botón de retroceso.",
},

TransactionModal:{
    transactionComplete: "Transacción Completa",
    transactionButton: "Aceptar"
},
AuthorizeSessionTimer:{
  title:"Tiempo Restante:"
}



// Credentials: {
//   Mycredential: {
//     OpenOnlineLearningCenter: "Centro de Aprendizaje en Línea Abierto",
//     manageMyTestAdministrations: "gestionarMisAdministracionesDePruebasUrl",
//     Resources: "Recursos",
//     TSCEOT: "TSC-EOT",
//   }
// }



  
  },
 
  

  };

  export default Spanish;
  