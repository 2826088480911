
import { useQuery } from '@tanstack/react-query';
import { request } from '../services/request';

export const useValidateCardOrder = () => {
    return useQuery<any, Error>(
        ['CardValidate'],
        async () => {
            const jsonResponse = await request({
                endpoint: 'certificates/ValidateCardOrder',                
            });

            if (!jsonResponse.success) {
                throw new Error(jsonResponse.data.Message || 'Something went wrong');
            }

            return jsonResponse;
        },
      {
    refetchOnWindowFocus: false,  

      }
    );
};
