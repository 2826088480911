import { useCredential } from "../../hooks/useCredential";
import React, { useEffect, useState } from "react";
import { BlockUI, Loader } from "../../components/Loader";
import { HostedForm } from "react-acceptjs";
import { useCreateCredentials } from "../../hooks/useCreateCredentials";
import { createCredential, workshopData } from "../../types/credential";
import { ErrorMessage, Field, Formik } from "formik";
import { createcredentialSchema } from "../../schemas/credential";
import InputMask from "react-input-mask";
import { useAuthData } from "../../hooks/useAuthData";
import { usePrerequisiteCredential } from "../../hooks/usePrerequisiteCredential";
import { StatusReason, getContactId, utcToLocalDateTime } from "../../tools/utils";
import { Link, useLocation, useParams } from "react-router-dom";
import { usePrerequisiteTree } from "../../hooks/usePrerequisiteTree";
import { AvailableCredential } from "../../types/allcredential";
import { PreReqCredentialCard } from "../../components/Cards/PreRequisiteCard";
import moment from "moment";
import { request } from '../../services/request';
import useIsMobile from "../../hooks/Helper/useIsMobile";
import AuthorizeSessionTimer from "../../components/PaymentTimer/AuthorizeSessionTimer";
import { useTranslation } from "react-i18next";
import { Certificate } from "crypto";
import { TransactionSuccessModal } from "../../components/Common/TransactionSuccessModal";
export const Credential = ({ headerMarginTop }: { headerMarginTop: number }) => {
  /* eslint-disable no-restricted-globals */
  /* eslint-disable no-unused-expressions */
  const { t, i18n } = useTranslation();
  const language = i18n.language; 
  const  id  =  window.location.pathname.split('/')[2];
  const contactId = getContactId();
  const authQuery = useAuthData("contact-credential");
  const AuthData = authQuery.data?.data.Data;
  const cCredentials = useCreateCredentials();
  const credentials = useCredential(id ,language);
  const preRequisiteQuery = usePrerequisiteCredential(id,language);
  const preRequisiteData = preRequisiteQuery.data?.data?.Data;

  const preRequisiteTreeQuery = usePrerequisiteTree(id,language);
  const preRequisiteTreeData = preRequisiteTreeQuery.data?.data?.Data;
  const preRequisiteTreeDataReversed = preRequisiteTreeData?.reverse();
  const userCredentials = credentials.data?.data?.Data || {} as AvailableCredential;
  const credentialObj = userCredentials;

  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [checkout, setcheckout] = useState(false);
  const [paymentVoucher, setPaymentVoucherVoucher] = useState('');
  const [closedWorkshopCode, setClosedWorkshopCode] = useState('');
  const [selectedWorkshop, setSelectedWorkshop] = useState({id:null,isClosed:false});
  const [isCreditInfo, setCreditInfo] = useState(false);
  const isDateWithinLast12Months = (dateString) => {
    const currentDate = new Date();
    const oneYearAgo = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    const startDate = new Date(oneYearAgo.getFullYear(), oneYearAgo.getMonth(), oneYearAgo.getDate(), 0, 0, 0); // Start of the range
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59); // End of the range

    const testDate = new Date(dateString.split('T')[0]);

    return testDate >= startDate && testDate <= endDate;
};

  const [isModalOpen, setIsModalOpen] = useState(false);


const recentExams = credentialObj?.ExamDetails?.filter((examDetail) => 
    isDateWithinLast12Months(examDetail.TestDate)
);

  const [creditData, setCreditData] = useState({
    LastFour: "",
    dataDescriptor: "",
    dataValue: "",
    CardNumber: "",
  });
  const isMobile=useIsMobile();
  let CreateCredentialsObj: createCredential = {
    ContactId: contactId,
    CredentialId: id,
    WorkShopId: selectedWorkshop.id,
    Payment: {
      Amount: 0,
      AuthNetTransactionID: "",
      CreditCardType: "",
      LastFour: "",
      Paymenttype: "Credit Card",
      PaymentData: {
        dataDescriptor: "",
        dataValue: "",
        Address: "",
        City: "",
        FirstName: "",
        LastName: "",
        State: "",
        Zip: "",
        BillToEmail: "",
        BillToName: "",
        BillingPhone: "",
        BillingCountry: "",
      },
    },
  };
  const initialValues = {
    Address: "",
    BillToEmail: "",
    City: "",
    FirstName: "",
    LastName: "",
    Zip: "",
    State: "",
    BillingPhone: "",
    BillingCountry: "",
  };

  const handleSubmits = (response: any) => {
    if (response.messages.message[0].code === "I_WC_01") {
      setCreditInfo(true);
      setCreditData({
        LastFour: response.encryptedCardData.cardNumber.replaceAll("X", ""),
        dataDescriptor: response.opaqueData.dataDescriptor,
        dataValue: response.opaqueData.dataValue,
        CardNumber: response.encryptedCardData.cardNumber,
      });
    }
  };

  const handleTermsAndConditionsChange = (event: any) => {
    setTermsAndConditions(event.target.checked);
  };
  const processedtocheckout = (value: boolean) => {
    setcheckout(value);
  };
  function onWorkshopChanged(value: any,closed:boolean=false) {
    console.log('object :>> ', value +" "+ closed);
    setSelectedWorkshop({id:value,isClosed:closed});
};
async function handlePaymentVoucherCode() {
  if(closedWorkshopCode.trim()){
      setClosedWorkshopError('')
      setWorkshopLoading(true);
      const jsonResponse = await request({
          endpoint: 'workshopcode',
          data: {
              credentialId:id,
              voucherCode:paymentVoucher
          },
      });

      if (!jsonResponse.success) {
          setClosedWorkshopError(jsonResponse.data.Message || 'Something went wrong');
          setWorkshopLoading(false);
      }
      if (jsonResponse.data.Data.length<=0) {
          setClosedWorkshopError(`No closed workshop available against this ${closedWorkshopCode} access code. `);
          setWorkshopLoading(false);
      }

      setWorkshopLoading(false);
      if(jsonResponse.data.Data){
          for (let index = 0; index < jsonResponse.data.Data.length; index++) {
            jsonResponse.data.Data[index]['closed']=true
              credentialObj.WorkShopsData.push(jsonResponse.data.Data[index])  
          }
          
          setClosedWorkshopList(jsonResponse.data.Data);
      }
  }

}

  function handleZeroFeeCredential() {
    const CreateCredentialsObj = {
      ContactId: contactId,
      CredentialId: id,
      WorkShopId:  selectedWorkshop.id,
      Payment: null,
    };
    cCredentials
      .mutateAsync(CreateCredentialsObj)
      .then((res) => {
        if (res?.data?.ApiCode === 200 && res.success === true) {
          window.location.href = "/testing-personnel/";
        }
      })
      .finally(() => {})
      .catch((err) => {
        console.log(err);
      });
  }
  const [isWorkshopLoading,setWorkshopLoading]=useState(false);
  const [closedWorkshopError,setClosedWorkshopError]=useState('');
  const [closedWorkshopList,setClosedWorkshopList]=useState<workshopData[]>([]);
  async function handleClosedWorkshopCode() {
      if(closedWorkshopCode.trim()){
          setClosedWorkshopError('')
          setWorkshopLoading(true);
          const jsonResponse = await request({
              endpoint: 'closedWorkshop',
              data: {
                  credentialId:id,
                  voucherCode:closedWorkshopCode
              },
          });
  
          if (!jsonResponse.success) {
              setClosedWorkshopError(jsonResponse.data.Message || 'Something went wrong');
              setWorkshopLoading(false);
          }
          if (jsonResponse.data.Data.length<=0) {
            setClosedWorkshopError(`No closed workshop available against this ${closedWorkshopCode} access code. `);
            setWorkshopLoading(false);
        }

          setWorkshopLoading(false);
          if(jsonResponse.data.Data){
              for (let index = 0; index < jsonResponse.data.Data.length; index++) {
                jsonResponse.data.Data[index]['closed']=true
                  credentialObj.WorkShopsData.push(jsonResponse.data.Data[index])  
              }
              setClosedWorkshopList(jsonResponse.data.Data);
          }
      }
  }

 
  const isValidated = (!termsAndConditions) || (credentialObj.WorkShopsData.length > 0 && !selectedWorkshop.id);
  const filteredWorkshops = credentialObj && credentialObj.WorkShopsData ? credentialObj.WorkShopsData.filter((WorkShop) => {
    if (closedWorkshopList.length > 0) {
      return WorkShop.closed === true;
    }
    return true;
  }) : [];
  



  const reset = () => {
    credentials.refetch();
  };


useEffect(() => {
    reset();
}, [language]);

  return (
    <>
      <div id="main-content" style={{ marginTop: headerMarginTop }}>
        <div className="cco_content_area cco_content_area_0">
          <article className="react-container">
            <div className="cco_section cco_section_0">
              <div className="cco_row cco_row_8">
                <div className="cco_col">
                  <div className="cco_text cco_text_11">
                    <div className="cco_text_inner">
                      <strong>
                        {/* <a href="/testing-personnel">Testing Personnel</a> */}
                        <Link
                          to={"/testing-personnel"}
                          onClick={() => {
                            reset();
                          }}
                        >
                          Testing Personnel
                        </Link>
                      </strong>{" "}
                      {">"} {credentialObj && credentialObj?.CredentialName || ""}
                      {isCreditInfo && <AuthorizeSessionTimer startTimer={isCreditInfo} />

}
                    </div>
                  </div>
                </div>
              </div>

              {!checkout && (
                <>
                  <div className="cco_row cco_row_7">
                    <div className="cco_col cco_col_6">
                      <div className="cco_text cco_text_10">
                        <div className="cco_text_inner">
                          <h2>
                         <strong>{(credentialObj.myCCOLabel && credentialObj.myCCOLabel + " Journey Status") ||
                              ""}</strong> 
                          </h2>  
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cco_row cco_row_3">
                    <div className="cco_col">
                      {preRequisiteTreeQuery.isLoading ? (
                        <Loader />
                      ) : preRequisiteTreeQuery.error ? (
                        <p>{preRequisiteTreeQuery.error.message}</p>
                      ) : preRequisiteTreeDataReversed &&
                        preRequisiteTreeDataReversed.length > 0 ? (
                        <div
                          className="cco_group cco_group_2"
                          style={{
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          { credentialObj?.Exams === true  && (credentialObj?.PreReqCredentialName === 'Proctor-Practical' || credentialObj?.PreReqCredentialName === 'MBL Workshop' || credentialObj?.PreReqCredentialName === 'THO Workshop') &&
    <>
<div className="cco_group_item" style={{ marginTop: '10px' }}>
    <a href={( recentExams.length === 0 && credentialObj.CertificationDetails.length === 0) ? "/dashboard/" :  ''} style={{ textDecoration: 'none', color: (recentExams.length === 0 && credentialObj.CertificationDetails.length === 0 ) ? 'red' : '#666' }}>
    <div className={`cco_card cco_card_myCredential ${
                  recentExams.length === 0
                        ? (credentialObj.CertificationDetails && credentialObj.CertificationDetails.length > 0 
                            ? 'purchased-card' 
                            : 'notcompleted-card') 
                        : 'purchased-card'
                }`}>
            <div className="cco_card_body">
                <div className="cco_card_main" style={{ width: "100%", padding: "10px" }}>
                    <div className="cco_text cco_text_myCredential">
                        <div className="cco_text_inner">
                        {
    credentialObj.CertificationDetails && credentialObj.CertificationDetails.length > 0 ? (
        credentialObj.CertificationDetails.map((certDetail, index) => (
            <div key={index}>
                <h3>{certDetail.CertificationTypeName}</h3>
                <div>Expiration Date: {certDetail.CertificationExpirationDate.split('T')[0] === '0001-01-01' ? 'Not Available' : certDetail.CertificationExpirationDate.split('T')[0]}</div>
            </div>
        ))
    ) : recentExams && recentExams.length > 0 ? (
      credentialObj.ExamDetails
          .filter((examDetail) => isDateWithinLast12Months(examDetail.TestDate))
          .map((examDetail, index) => (
              <div key={index}>
                  <h3><strong>{examDetail.CredentialNameType}</strong></h3>
                  <div>Status: {examDetail.OverallStatus}</div>
                  <div>Test Date: {examDetail.TestDate.split('T')[0] === '0001-01-01' ? 'Not Available' : examDetail.TestDate.split('T')[0]}</div>
              </div>
          ))
    ) : (
        <>
            <h3><strong>Written Exam Required </strong></h3>
            <div>Click here to sign up</div>
        </>
    )
}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </a>
</div>




         
        <div className="desktop-arrow"></div>
        <div className="mobile-arrow"></div>
    </>
}

{ credentialObj?.Certifications === true  && (credentialObj?.PreReqCredentialName === 'Proctor-Practical' || credentialObj?.PreReqCredentialName === 'MBL Workshop' || credentialObj?.PreReqCredentialName === 'THO Workshop') &&
    <>
<div className="cco_group_item" style={{ marginTop: '10px' }}>
    <a href={(credentialObj.ExamDetails.length === 0 ) ? "/dashboard/" :  ''} style={{ textDecoration: 'none', color: (credentialObj.CertificationDetails.length === 0 ) ? 'red' : '#666' }}>
        <div className={`cco_card cco_card_myCredential ${credentialObj.CertificationDetails.length === 0  ? 'notcompleted-card' : 'purchased-card'}`}>
            <div className="cco_card_body">
                <div className="cco_card_main" style={{ width: "100%", padding: "10px" }}>
                    <div className="cco_text cco_text_myCredential">
                        <div className="cco_text_inner">
                            {credentialObj.CertificationDetails.length === 0  ? (
                                <>
                                    <h3><strong>Certification Required </strong></h3>
                                    <div>Click here to sign up</div>
                                </>
                            ) : (
                                credentialObj.CertificationDetails.map((certDetail, index) => (
                                    <div key={index}>
                                        <h3><strong>{certDetail.CertificationTypeName}</strong></h3>
                                        <div>Expiration Date: {certDetail.CertificationExpirationDate.split('T')[0] === '0001-01-01' ? 'Not Available' : certDetail.CertificationExpirationDate.split('T')[0]}</div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </a>
</div>




         
        <div className="desktop-arrow"></div>
        <div className="mobile-arrow"></div>
    </>
}
                          {preRequisiteTreeDataReversed.map(
                            (credential, index) => (
                              <>
                                 <PreReqCredentialCard 
                                                credential={credential}
                                                index={index}
                                                key={credential.PreReqCredentialId+index}
                                                />
                              </>
                            )
                          )}

                          <div
                            className="cco_group_item"
                            style={{ marginTop: "10px" }}
                          >
                            <a href={"javascript:void(0)"} style={{ color: "#666" }}>
                              <div
                                className={`cco_card cco_card_myCredential`}
                                style={{
                                  backgroundColor: "#c67b05",
                                  color: "#ffffff",
                                }}
                              >
                                <div className="cco_card_body">
                                  <div
                                    className="cco_card_main"
                                    style={{ width: "100%", padding: "10px" }}
                                  >
                                    <div className="cco_text cco_text_myCredential">
                                      <div className="cco_text_inner">
                                        <h3 style={{ color: "#ffffff" }}>
                                          {credentialObj.myCCOLabel}{" "}
                                        </h3>
                                        <div>Status: {"Not Purchased"}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      ) : (
                        <p>
                          {" "}
                          There are no pre-requisites for{" "}
                          {credentialObj && credentialObj.CredentialName}{" "}
                          credential.
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}

              {checkout && termsAndConditions && (
                <>
                  <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={createcredentialSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      if (isCreditInfo == true) {
                        CreateCredentialsObj = {
                          ContactId: contactId,
                          CredentialId: id,
                          WorkShopId: selectedWorkshop.id,
                          Payment: {
                            Amount: credentialObj.CredentialFee.Price,
                            AuthNetTransactionID: "",
                            CreditCardType: "",
                            LastFour: creditData.LastFour,
                            Paymenttype: "Credit Card",
                            PaymentData: {
                              dataDescriptor: creditData.dataDescriptor,
                              dataValue: creditData.dataValue,
                              Address: values.Address,
                              City: values.City,
                              FirstName: values.FirstName,
                              LastName: values.LastName,
                              State: values.State,
                              Zip: values.Zip,
                              BillToEmail: values.BillToEmail,
                              BillToName:
                                values.FirstName + " " + values.LastName,
                              BillingCountry: values.BillingCountry,
                              BillingPhone: values.BillingPhone,
                            },
                          },
                        };
                        cCredentials
                          .mutateAsync(CreateCredentialsObj)
                          .then((res) => {
                            if (
                              res?.data?.ApiCode === 200 &&
                              res.success === true
                            ) {
                              setSubmitting(false);
                              setIsModalOpen(true);
                              // window.location.href = "/testing-personnel/";
                            }
                          })
                          .finally(() => {
                            setSubmitting(false);
                          })
                          .catch((err) => {
                            console.log(err);
                            setSubmitting(false);
                          });
                      } else {
                        setSubmitting(false);
                        return;
                      }
                    }}
                  >
                    {({
                      values,
                      isSubmitting,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      handleReset,
                      isValid,
                      dirty,
                    }) => (
                      <form
                        onReset={handleReset}
                        onSubmit={(e) => {
                          if (isSubmitting) {
                            e.preventDefault();
                          } else {
                            handleSubmit(e);
                          }
                        }}
                        className="cco_form"
                      >
                        <div className="cco_row">
                          <div className="cco_col">
                            <>
                              <div className="cco_form_row">
                                <div className="cco_form_col">
                                  <h2 className="cco_form_heading">
                                    Billing Information
                                  </h2>
                                  <p  style={{paddingBottom:'20px'}} ><strong>Note: We Accept VISA, MasterCard, and American Express.</strong></p>
                                  <div className="cco_form_wrap">
                                    <div className="cco_form_row">
                                      <div
                                        className="cco_form_col"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        <div className="cco_form_wrap">
                                          <div
                                            className="cco_blurb cco_blurb_4"
                                            style={{ alignItems: "center" }}
                                          >
                                            {isCreditInfo && (
                                              <>
                                                <div className="cco_blurb_main">
                                                  {" "}
                                                  Credit Card Number
                                                </div>
                                                <div className="cco_blurb_aside">
                                                  {creditData?.CardNumber || ""}
                                                </div>
                                              </>
                                            )}
                                            {!isCreditInfo && (
                                              <>
                                                <HostedForm
                                                  buttonText={
                                                    "Click here to fill Credit Card Information"
                                                  }
                                                  formButtonText={"Continue"}
                                                  containerClassName={
                                                    "cco_menu_item"
                                                  }
                                                  buttonStyle={{
                                                    backgroundColor: "#c67b05",
                                                    color: "#ffffff",
                                                    fontFamily: '"DMSans Bold"',
                                                    fontSize: "18px",
                                                    fontWeight: "700",
                                                    lineHeight: "1",
                                                    textAlign: "center",
                                                    display: "inline-flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    maxWidth: "100%",
                                                    width: "100%",
                                                    padding: "10px",
                                                    border: "none",
                                                    opacity: isValidated
                                                      ? ".5"
                                                      : "1",
                                                    cursor: isValidated
                                                      ? "not-allowed"
                                                      : "pointer",
                                                  }}
                                                  containerStyle={{
                                                    backgroundColor: "#c67b05",
                                                    color: "#ffffff",
                                                    fontFamily: '"DMSans Bold"',
                                                    fontSize: "18px",
                                                    fontWeight: "700",
                                                    lineHeight: "1",
                                                    textAlign: "center",
                                                    display: "inline-flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    maxWidth: "100%",
                                                    minHeight: "42px",
                                                    padding: "0px 0px",
                                                    border: "2px solid #c67b05",
                                                    borderRadius: "21px",
                                                    cursor: isValidated
                                                      ? "not-allowed"
                                                      : "pointer",
                                                  }}
                                                  formHeaderText={
                                                    credentialObj.CredentialName +
                                                    " Payment"
                                                  }
                                                  authData={{
                                                    apiLoginID:
                                                      AuthData?.ApiLoginID ||
                                                      "",
                                                    clientKey:
                                                      AuthData?.ClientKey || "",
                                                  }}
                                                  environment={
                                                    AuthData?.IsSandbox
                                                      ? "SANDBOX"
                                                      : "PRODUCTION"
                                                  }
                                                  onSubmit={handleSubmits}
                                                  billingAddressOptions={{
                                                    show: false,
                                                    required: false,
                                                  }}
                                                  errorTextStyle={{
                                                    fontSize: "12px",
                                                  }}
                                                />
                                                {!isCreditInfo &&
                                                  isSubmitting && (
                                                    <p
                                                      style={{
                                                        color: "red",
                                                        textAlign: "center",
                                                        padding: "10px",
                                                      }}
                                                    >
                                                      Please fill credit card
                                                      information!
                                                    </p>
                                                  )}
                                                  {!isMobile && 
    <ul className="cco_menu cco_menu_0" style={{display: 'contents',alignItems:'baseline', padding: 0}}>
        <li className="cco_menu_item cco_menu_item_primary">
            <span className='cco_form_message cco_form_message_error'>{closedWorkshopError}</span>
        </li>
        {/* <li className="cco_menu_item cco_menu_item_primary ">
            <input placeholder='Enter voucher code' type='text' onChange={(e)=>{setPaymentVoucherVoucher(e.target.value)}} />
        </li> */}
        {/* <li className="cco_menu_item cco_menu_item_primary">
            <a title={isWorkshopLoading?'Searching...':closedWorkshopList.length>0?'Access Code Redeemed':''} style={{ maxWidth: '200px',minHeight:'30px',height:'30px !important'}} className={(isWorkshopLoading || closedWorkshopList.length>0)?'btn-disabled':''} href='#'
            onClick={(e)=>{
                if(!isWorkshopLoading && closedWorkshopList?.length<=0){
                    handlePaymentVoucherCode();
                }
                e.preventDefault();
            }}
            >
                Voucher Code</a>
          
        </li> */}
    </ul>
}

                                              </>
                                            )}
                                          </div>
                                          {/* {isMobile && 
    <ul className="cco_menu cco_menu_0" style={{display: 'contents',alignItems:'baseline', padding: 0}}>
        <li className="cco_menu_item cco_menu_item_primary">
            <span className='cco_form_message cco_form_message_error'>{closedWorkshopCode}</span>
        </li>
        <li className="cco_menu_item cco_menu_item_primary ">
            <input placeholder='Enter voucher code' type='text' onChange={(e)=>{setPaymentVoucherVoucher(e.target.value)}} />
        </li>
        <li className="cco_menu_item cco_menu_item_primary">
            <a title={isWorkshopLoading?'Searching...':closedWorkshopList.length>0?'Access Code Redeemed':''} style={{ maxWidth: '200px',minHeight:'30px',height:'30px !important'}} className={(isWorkshopLoading || closedWorkshopList.length>0)?'btn-disabled':''} href='#'
            onClick={(e)=>{
                if(!isWorkshopLoading && closedWorkshopList?.length<=0){
                    handlePaymentVoucherCode();
                }
                e.preventDefault();
            }}
            >
                Voucher Code</a>
          
        </li>
    </ul>
}             */}
                                        </div>
                                      </div>
                                    </div>

                                    <ul className="cco_form_fieldset">
                                      <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                        <label htmlFor="ccoFullName">
                                          First Name{" "}
                                          <span style={{ color: "#db3a3a" }}>
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          type="text"
                                          id="ccoCity"
                                          name="FirstName"
                                        />
                                        <ErrorMessage
                                          name={"FirstName"}
                                          component={"div"}
                                          className="cco_form_message cco_form_message_error"
                                        />
                                      </li>
                                      <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                        <label htmlFor="ccoFullName">
                                          Last Name{" "}
                                          <span style={{ color: "#db3a3a" }}>
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          type="text"
                                          id="ccoCity"
                                          name="LastName"
                                        />
                                        <ErrorMessage
                                          name={"LastName"}
                                          component={"div"}
                                          className="cco_form_message cco_form_message_error"
                                        />
                                      </li>
                                      <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                        <label htmlFor="ccoEmail">
                                          Email{" "}
                                          <span style={{ color: "#db3a3a" }}>
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          type="text"
                                          id="ccoCity"
                                          name="BillToEmail"
                                        />
                                        <ErrorMessage
                                          name={"BillToEmail"}
                                          component={"div"}
                                          className="cco_form_message cco_form_message_error"
                                        />
                                      </li>
                                      <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                        <label htmlFor="ccoPhoneBilling">
                                          Phone{" "}
                                          <span style={{ color: "#db3a3a" }}>
                                            *
                                          </span>
                                        </label>

                                        <InputMask
                                          type={"tel"}
                                          id={"ccoPhoneBilling"}
                                          mask="+1 (999) 999-9999"
                                          value={values.BillingPhone}
                                          onChange={handleChange(
                                            "BillingPhone"
                                          )}
                                          onBlur={handleBlur("BillingPhone")}
                                        />

                                        <ErrorMessage
                                          component={"div"}
                                          name={"BillingPhone"}
                                          className="cco_form_message cco_form_message_error"
                                        />
                                      </li>
                                      <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                        <label htmlFor="ccoBillingCountry">
                                          Country{" "}
                                          <span style={{ color: "#db3a3a" }}>
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          type="text"
                                          id="ccoBillingCountry"
                                          name="BillingCountry"
                                        />
                                        <ErrorMessage
                                          name={"BillingCountry"}
                                          component={"div"}
                                          className="cco_form_message cco_form_message_error"
                                        />
                                      </li>

                                      <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                        <label htmlFor="ccoFullName">
                                          Address{" "}
                                          <span style={{ color: "#db3a3a" }}>
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          type="text"
                                          id="ccoCity"
                                          name="Address"
                                        />
                                        <ErrorMessage
                                          name={"Address"}
                                          component={"div"}
                                          className="cco_form_message cco_form_message_error"
                                        />
                                      </li>
                                      <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                        <label htmlFor="ccoCity">
                                          City{" "}
                                          <span style={{ color: "#db3a3a" }}>
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          type="text"
                                          id="ccoCity"
                                          name="City"
                                        />
                                        <ErrorMessage
                                          name={"City"}
                                          component={"div"}
                                          className="cco_form_message cco_form_message_error"
                                        />
                                      </li>

                                      <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                        <label htmlFor="ccoState">
                                          State{" "}
                                          <span style={{ color: "#db3a3a" }}>
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          type="text"
                                          id="ccoCity"
                                          name="State"
                                        />
                                        <ErrorMessage
                                          name={"State"}
                                          component={"div"}
                                          className="cco_form_message cco_form_message_error"
                                        />
                                      </li>
                                      <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                        <label htmlFor="ccoFullName">
                                          Zip{" "}
                                          <span style={{ color: "#db3a3a" }}>
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          type="text"
                                          id="ccoCity"
                                          name="Zip"
                                        />
                                        <ErrorMessage
                                          name={"Zip"}
                                          component={"div"}
                                          className="cco_form_message cco_form_message_error"
                                        />
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="cco_form_row">
                                <div className="cco_form_col">
                                  {!isSubmitting && (
                                    <>
                                      {cCredentials.data?.success && (
                                        <div
                                          className="cco_form_message cco_form_message_success"
                                          style={{
                                            fontSize: 16,
                                            marginBottom: 20,
                                          }}
                                        >
                                          <strong>
                                            Request submited successfully.
                                          </strong>
                                        </div>
                                      )}

                                      {cCredentials.error && (
                                        <div
                                          className="cco_form_message cco_form_message_error"
                                          style={{
                                            marginBottom: 20,
                                          }}
                                        >
                                          {
                                            (cCredentials.error as Error)
                                              .message
                                          }
                                        </div>
                                      )}
                                    </>
                                  )}
                                  <ul className="cco_menu cco_menu_7">
                                    <li className="cco_menu_item">
                                      <button
                                        className="cco_form_submit"
                                        disabled={
                                          !(isValid && dirty) && !isCreditInfo
                                        }
                                        type={"submit"}
                                        style={{
                                          opacity:
                                            isValid && dirty && isCreditInfo
                                              ? "1"
                                              : ".5",
                                          cursor:
                                            isValid && dirty && isCreditInfo
                                              ? "pointer"
                                              : "not-allowed",
                                        }}
                                      >
                                        {cCredentials.isLoading ||
                                        (cCredentials.isSuccess && isCreditInfo)
                                          ? "Processing"
                                          : "Pay $" +
                                            credentialObj.CredentialFee.Price}
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </>
              )}

              {!checkout && (
                <>
                  <div className="cco_row ">
                    <div className="cco_col">
                      <div className="cco_text cco_text_0">
                        <div className="cco_text_inner">
                          <strong>
                            {credentialObj?.myCCOLabel|| ""} Credential
                            Information
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cco_row">
                    <div className="cco_col">
                      {credentials.isLoading ? (
                        <Loader />
                      ) : credentials.error ? (
                        <p>{credentials.error.message}</p>
                      ) : (
                        <div className="cco_card cco_card_2">
                          <div className="cco_card_body"></div>
                          {/* Credential Description */}
                          {credentialObj?.CredentialLocalizationDetails && credentialObj.CredentialLocalizationDetails.length > 0 ? (
    <div className="cco_card_footer">
        <div className="cco_text">
            <div
                className="cco_text_inner"
                dangerouslySetInnerHTML={{
                    __html: credentialObj.CredentialLocalizationDetails[0]?.ProductInfo || ''
                }}
            ></div>
        </div>
    </div>
) : (
    <p>Credential information is empty</p>
)}

                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              {/* WorkShop Lists */}
              {credentialObj.Workshops == true && !checkout && (
                <>
                  <div className="cco_row cco_row_2">
                    <div className="cco_col">
                      <div className="cco_text cco_text_2">
                      <div className="cco_text_inner" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
    <h2><strong>Available Workshops </strong></h2>
    <ul className="cco_menu cco_menu_0" style={{display: 'flex',alignItems:'baseline', padding: 0}}>
        <li className="cco_menu_item cco_menu_item_primary">
            <span className='cco_form_message cco_form_message_error'>{closedWorkshopError}</span>
        </li>
        <li className="cco_menu_item cco_menu_item_primary ">
            <input placeholder='Enter access code' type='text' onChange={(e)=>{setClosedWorkshopCode(e.target.value)}} />
        </li>
        <li className="cco_menu_item cco_menu_item_primary">
            <a title={isWorkshopLoading?'Searching...':closedWorkshopList.length>0?'Access Code Redeemed':''} style={{ maxWidth: '200px',minHeight:'30px',height:'30px !important'}} className={(isWorkshopLoading || closedWorkshopList.length>0)?'btn-disabled':''} href='#'
            onClick={(e)=>{
                if(!isWorkshopLoading && closedWorkshopList?.length<=0){
                    handleClosedWorkshopCode();
                }
                e.preventDefault();
            }}
            >
                {isWorkshopLoading ?(
                    "Loading"
                ):(
                    "Access Code"
                )}
                </a>
          
        </li>
    </ul>
</div>
                      </div>
                    </div>
                  </div>
                  <div className="cco_row cco_row_3">
                                <div className="cco_col">
                                    {credentials.isLoading ? (<Loader />) : credentials.error ? (<p>{credentials.error.message}</p>) : (
                                        credentialObj.WorkShopsData.length > 0 ? (
                                            <ul className="cco_accordion">
                                                <div className="cco_text cco_text_8">
                                                    <p>You must select one workshop to proceed.</p>
                                                </div>
                                                {filteredWorkshops.map((WorkShop: workshopData, index: number) => {

                                                    return (
                                                        <li className="cco_accordion_item active" key={index} style={{ border: selectedWorkshop.id === WorkShop.Id ? "2px solid #c67b1d" : '' }}>
                                                            <div className="cco_accordion_item_body">
                                                                <table className="cco_table">
                                                                    <tbody className="cco_table_body">
                                                                        <tr className="cco_table_row">
                                                                            <td className='cco_table' style={{ paddingLeft: "20px", paddingTop: "25px" }}>
                                                                                <div className='cco_form_fieldset_item'>
                                                                                    <label className="cco_form_checkbox_0">
                                                                                        <input id={WorkShop.Id} type="radio" name={"workshop" + index}
                                                                                            onChange={(e) => {onWorkshopChanged(e.target.value,WorkShop.closed);console.log('workshop :>> ', WorkShop);}} value={WorkShop.Id} checked={selectedWorkshop.id === WorkShop.Id} className="cco_form_checkbox_input" />
                                                                                        <span className="cco_form_checkbox_checkmark" style={{ border: selectedWorkshop.id === WorkShop.Id ? "2px solid #c67b1d" : '1px solid #c67b1d' }}></span>
                                                                                    </label>
                                                                                </div>

                                                                            </td>
                                                                            <td className="cco_table_cell cco_table_cell_large">
                                                                                <div className="cco_blurb cco_blurb_0">
                                                                                    <div className="cco_blurb_label">Start Date/Time*</div>
                                                                                    <div className="cco_blurb_value">
                                                                                    <strong>
                                                                                    
  {(WorkShop.Format === 'Virtual')?utcToLocalDateTime(WorkShop.StartDateTime  || '-'): (moment(WorkShop.StartDateTime).format('MM-DD-YYYY hh:mmA') + " (" + (WorkShop.StartdateTimeZone || '-') + ")")}

  
  

                    </strong>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="cco_blurb cco_blurb_0">
                                                                                    <div className="cco_blurb_label">End Date/Time*</div>
                                                                                    <div className="cco_blurb_value">
                                                                                    <strong>
                                                                                     
                         
                                                                                    {(WorkShop.Format === 'Virtual')?utcToLocalDateTime(WorkShop.EndDateTime  || '-'): (moment(WorkShop.EndDateTime).format('MM-DD-YYYY hh:mmA') + " (" + (WorkShop.EnddateTimeZone || '-') + ")")}
                           
                    </strong>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="cco_blurb cco_blurb_0">
                                                                                    <div className="cco_blurb_label">Location</div>
                                                                                    <div className="cco_blurb_value">
                                                                                        <strong>{WorkShop.LocationName || '-'}</strong>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="cco_table_cell">
                                                                                <div className="cco_blurb cco_blurb_align_right cco_blurb_0">
                                                                                    <div className="cco_blurb_label">Available Seats</div>
                                                                                    <div className="cco_blurb_value">
                                                                                        <strong>{WorkShop.AvailableSeats || '-'}</strong>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="cco_blurb cco_blurb_align_right cco_blurb_0">
                                                                                    <div className="cco_blurb_label">Application Deadline</div>
                                                                                    <div className="cco_blurb_value">
                                                                                        <strong>{utcToLocalDateTime(WorkShop.ApplicationDeadline) || '-'}</strong>
                                                                                    </div>
                                                                                </div>
                                                                            {WorkShop.Format==="In_Person" && 
                                                                            <div className="cco_blurb cco_blurb_align_right cco_blurb_0">
                                                                            <div className="cco_blurb_label">Audit Event</div>
                                                                            <div className="cco_blurb_value">
                                                                                <strong>{WorkShop.AuditEvent?WorkShop.AuditEvent.split(/(?=[A-Z])/).join(' '):'N/A'}</strong>
                                                                            </div>
                                                                        </div>
                                                                            }
                                                                                
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </li>
                                                    );

                                                })}
                                                {isWorkshopLoading && 
                                                <Loader />
                                                }
                                            </ul>
                                        ) : (
                                        !isWorkshopLoading ?(
                                            <p style={{ color: 'red', fontWeight: "bold" }} >
                                            Sorry, there are currently no available workshops. You are not able to continue until a workshop becomes available. If you'd like to be added to the waitlist, please email:<a href='mailto:proctorworkshops@nccco.org'>proctorworkshops@nccco.org</a>. Provide your name, CCO ID, and indicate which workshop you are interested in attending. We will notify you when additional workshops are added.
                                            </p>):(
                                            //   <div className="spinner_container">
                                            //   <div className="spinner_loader"></div>
                                            // </div>
                                            <Loader />
                                            )
                              
                                        )
                                         )}
                                </div>
                            </div></>
                    )}
              {/* {cCredentials.isLoading || cCredentials.isSuccess ?'' : ( */}

              {!checkout && (
                <>
                  <div className="cco_row cco_row_2">
                    <div className="cco_col">
                      <div className="cco_text cco_text_0">
                        <div className="cco_text_inner">
                          <strong>Agreement</strong>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="cco_row cco_row_9">
  {credentials.isLoading ? (
    <Loader />
  ) : credentials.error ? (
    <p>{credentials.error.message}</p>
  ) : (
    <div className="cco_col">
  <div className="cco_text cco_text_14">
    <div
      className="cco_text_inner"
      dangerouslySetInnerHTML={{
        __html:
          credentialObj?.CredentialLocalizationDetails?.[0]?.Agreement 
          || "",
      }}
    ></div>
  </div>
</div>

  )}
</div>


                  {(preRequisiteData?.Status === StatusReason.Approved || preRequisiteData?.Status === StatusReason.PendingRenewal || preRequisiteData === null) &&
                      
                  <div className="cco_form_row">
                    <div className="cco_form_col">
                      {credentials.isLoading ? (
                        <Loader />
                      ) : credentials.error ? (
                        <p>{credentials.error.message}</p>
                      ) : (
                        <ul className="cco_menu cco_menu_4">
                          <li
                            className={"cco_menu_item cco_menu_item_checkbox"}
                          >
                            <label
                              className={
                                "cco_form_checkbox cco_form_checkbox_0"
                              }
                            >
                              <input
                                disabled={
                                  !credentialObj.CertificationDetails.length && credentialObj.Certifications|| recentExams && !recentExams.length  && credentialObj.Exams && !credentialObj.CertificationDetails.length ||!credentialObj.WorkShopsData.length &&
                                  credentialObj.Workshops
                                }
                                name="termsAndConditions"
                                id="ccoTermsAndConditions"
                                type="checkbox"
                                className={"cco_form_checkbox_input"}
                                value="termsAndConditions"
                                onChange={handleTermsAndConditionsChange}
                              />
                              <span
                                className={"cco_form_checkbox_checkmark"}
                                style={{
                                  border: termsAndConditions
                                    ? "2px solid #c67b1d"
                                    : "1px solid #c67b1d",
                                  cursor:
                                  ! credentialObj.CertificationDetails.length && credentialObj.Certifications || recentExams && !recentExams.length   && credentialObj.Exams && !credentialObj.CertificationDetails.length || !credentialObj.WorkShopsData.length &&
                                    credentialObj.Workshops
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              ></span>
                              <p style={{ fontSize: "12px" }}>
                                I understand and agree to this Agreement. I
                                further understand and agree that a payment to
                                register or enroll in any testing personnel
                                training (such as a course or a workshop) cannot
                                be refunded once my request and payment are
                                submitted to CCO. In addition, I understand that
                                any such registration cannot be cancelled or
                                transferred. By submitting a payment, I agree to
                                CCO's{" "}
                                <a
                                  style={{
                                    color: "#c67b05",
                                    padding: "0px",
                                    alignItems: "normal",
                                  }}
                                  href="https://www.nccco.org/nccco/about-nccco/policies/financial-terms-conditions"
                                >
                                  Financial Terms and Conditions
                                </a>
                                .
                              </p>
                            </label>
                          </li>
                          {! credentialObj.CertificationDetails.length && credentialObj.Certifications|| recentExams && !recentExams.length  &&  credentialObj.Exams && !credentialObj.CertificationDetails.length || !credentialObj.WorkShopsData.length &&
                          credentialObj.Workshops ? (
                            <li
                              className={`cco_menu_item cco_menu_item_btn cco_menu_item_btn_disabled`}
                            >
                              <a
                                href={`javascript:void(0)`}
                                onClick={event?.preventDefault}
                              >
                                Proceed to checkout
                              </a>
                            </li>
                          ) : (
                            <li
                              className={`cco_menu_item cco_menu_item_btn ${
                                isValidated ? "cco_menu_item_btn_disabled" : ""
                              }`}
                            >
                              {/* <a href={(!termsAndConditions) || credentialObj.WorkShopsData.length > 0 && !workshopId ? `javascript:void(0)` : `javascript:void(0)`} onClick={() => { isValidated ? event?.preventDefault : credentialObj.CredentialFee.Price === 0 ? handleZeroFeeCredential() : processedtocheckout(true); }}>{credentialObj.CredentialFee.Price === 0 ? 'Submit' : "Proceed to checkout"}</a> */}
                              <a
                                href={
                                  !termsAndConditions ||
                                  (credentialObj.WorkShopsData.length > 0 &&
                                    !selectedWorkshop.id)
                                    ? "javascript:void(0)"
                                    : "javascript:void(0)"
                                }
                                onClick={() => {
                                  if (isValidated) {
                                    event?.preventDefault();
                                  } else if (
                                    (credentialObj.CredentialFee.Price === 0 || (selectedWorkshop.isClosed===true && credentialObj.ClosedWorkshopFee.Price===0)) 
                                  ) {
                                    handleZeroFeeCredential();
                                  } else {
                                    processedtocheckout(true);
                                  }
                                }}
                              >
                                {                                    (credentialObj.CredentialFee.Price === 0 || (selectedWorkshop.isClosed===true && credentialObj.ClosedWorkshopFee.Price===0))
                                  ? "Submit"
                                  : "Proceed to checkout"}
                              </a>
                            </li>
                          )}
                        </ul>
                      )}
                    </div>
                  </div>
}
                </>
              )}
            </div>
          </article>
        </div>
      </div>

      <BlockUI
       
        title="Please wait while payment is processed. Do not refresh page or click back button."
        blocking={cCredentials.isLoading || cCredentials.isSuccess && !isModalOpen}
      />

                <TransactionSuccessModal 
                    isModalOpen={isModalOpen}
                    url='/testing-personnel/'
                    toggleModal={() => setIsModalOpen(!isModalOpen)}
      
                  />
    </>
  );
};
