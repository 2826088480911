import { useMsal } from "@azure/msal-react";
import { getContactId, getToken} from "../tools/utils";

type IGenericObject = {
    [key: string]: any;
};

interface IRequestOptions {
    endpoint: string;
    signal?: AbortSignal;
    type?: 'json' | 'form' | 'data';
    data?: IGenericObject | FormData;
    method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
    isPrivate?:boolean
}


export const request = async ({ endpoint, type = 'json', data, method = 'GET', signal ,isPrivate=false }: IRequestOptions) => {
    const {REACT_APP_WEB_API,REACT_APP_HEADERKEY}=process.env;
    const contactId =await getContactId()
    const tokenId=await getToken();
    const headers: IGenericObject = {};

    if (type) {
        switch (type) {
            case 'json':
                if ('GET' !== method) {
                    headers['Content-Type'] = 'application/json';
                }
                break;

            case 'data':
                // headers['Content-Type'] = 'multipart/form-data';
                break;
        }
    }

    const response = await fetch(
        `${REACT_APP_WEB_API}${endpoint}${isPrivate ? `?isPrivate=${isPrivate}&contactId=${contactId}` : `?contactId=${contactId}`}${
            data && 'GET' === method && !(data instanceof FormData) ? `&${new URLSearchParams(data)}` : ''
        }`,
        {
            method,
            signal,
            body: data
                ? data instanceof FormData
                    ? data
                    : 'GET' === method
                    ? undefined
                    : JSON.stringify(data)
                : undefined,
            headers: {
                ...headers,
                Accept: 'application/json',
                Authorization: `Bearer ${tokenId}`,
                "Access-Control-Allow-Origin": "*",
                "Head-Key":REACT_APP_HEADERKEY  
            },
            
        }
    );

    const success = response.ok;

    return {
        success,
        data: await response.json(),
    };
}