import { useQuery } from '@tanstack/react-query';
import { request } from '../services/request';
// import { sort } from 'fast-sort';
import { RootObject } from '../types/mycertifications';

export const useMyCerts = () => {
    return useQuery<RootObject, Error>(['myCerts'], async () => {
        const jsonResponse = await request({
            endpoint: 'GetMyCertifications',
        });

        if (!jsonResponse.success) {
            throw new Error(jsonResponse.data.Message || 'Something went wrong');
        }

        // if (Array.isArray(jsonResponse.data)) {
        //     jsonResponse.data = sort(jsonResponse.data).desc((cert: any) => new Date(cert.CertExpirationDate));
        // }

        return jsonResponse;
    },{
    refetchOnWindowFocus: false,});
};
