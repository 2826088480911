type IGenericObject = {
    [key: string]: any;
};

interface IRequestOptions {
    endpoint: string;
    signal?: AbortSignal;
    data?: IGenericObject;
}

export const faceRequest = async ({ endpoint, data}: IRequestOptions) => {
    const headers: IGenericObject = {};
    const {REACT_APP_FACE_API,REACT_APP_FACE_HEADERKEY}=process.env;

    const response = await fetch(`${REACT_APP_FACE_API}${endpoint}?${data && `${new URLSearchParams(data)}`}`,
        {
            method:'POST',
            body: JSON.stringify(data),
            headers: {
                ...headers,
                "Accept": 'application/json',
               'Ocp-Apim-Subscription-Key':REACT_APP_FACE_HEADERKEY,
               'Content-Type':'application/json'
            },
        }
    );

    const success = response.ok;

    return {
        success,
        data: await response.json(),
        status:response.status
    };
};
