import { useTranslation } from 'react-i18next';
import { usePaymentTimer } from '../../hooks/usePaymentTimer';

interface ITimer{
    startTimer:boolean 
}

const AuthorizeSessionTimer = ({ startTimer }:ITimer) => {
    const { timeRemaining, formatTime } = usePaymentTimer(startTimer);
  const { t } = useTranslation();
  return (
    <span className={timeRemaining>0?'paymentTimer':''} style={{float:'right'}}>{t("AuthorizeSessionTimer.title")} {formatTime(timeRemaining)}
      <br />
    </span>  
  );
};

export default AuthorizeSessionTimer;