import { getToken } from "../tools/utils";

type IGenericObject = {
    [key: string]: any;
};

interface IRequestOptions {
    endpoint: string;
    signal?: AbortSignal;
    data?: IGenericObject;
}

export const generatePDFRequest = async ({ endpoint, data}: IRequestOptions) => {
    const tokenId=await getToken();
   
    
    const response = await fetch(`${process.env.REACT_APP_NCCCO_BASE_URL}${endpoint}?${data && `${new URLSearchParams(data)}`}`,
    {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${tokenId}`,
            apiKey: `${process.env.REACT_APP_NCCCO_API_KEY}`,
        },
    }
);

    
    const success = response.ok;


    return {
        success,
        data: await response,
        status:response.status
    };
};
